@import "config";

body {
  background-color: $morado;

  &::-webkit-scrollbar {
    width: 8px;
    left: -20px;
    background: $grisf0;
  }

  &::-webkit-scrollbar-thumb {
    background: $rojoImperial;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba($gris64, 1);
  }

  &::-webkit-scrollbar-thumb:active {
    background: rgba($gris64, 1);
  }

  .container::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-track:hover,
  &::-webkit-scrollbar-track:active {
    background: transparent;
  }
}

.shadow-home {
  display: block;
  z-index: 9999;

  @media (min-width: 1089.99px) {
    background: linear-gradient(360deg, #4c12a1, #430f90, #2a065f);
  }
}

.title-section {
  font-family: $lato;
  color: $blanco;
  font-weight: bold;
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  animation: fade-in-init-general 1s;

  @media (min-width: 799px) {
    font-size: 3.5rem;
  }

  @media (max-width: 800px) {
    font-size: 34px;
  }
}

/**
 *  Sections for scroll animation
 */
.section-scroll {
  min-height: 300px;
}

// .lo-mas-destacado {
//   width: 90%;
//   margin-left: auto;
//   margin-right: auto;
//   min-height: 421px;
//   margin-bottom: 80px;
//   box-shadow: 3px 3px 10px #00000066;
//   border-radius: 50px;
//   background: linear-gradient(133deg, #7d46ce, #4c12a1);
// }

.metricas-home,
.metricas-nosotros {
  .card-metrica {
    @media (max-width: 1099.99px) {
      width: 50%;
      margin-top: 30px;
    }
  }
}

.metricas-home {
  .card-metrica {
    @media (min-width: 1100px) {
      width: calc(100% / 4 - 100px);

      &:not(:last-child) {
        margin-right: 50px;
      }
    }
  }
}

.metricas-nosotros {
  flex-wrap: wrap;

  .card-metrica {
    @media (min-width: 1100px) {
      width: calc(100% / 3 - 100px);
    }
  }
}

.metricas {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding-top: 50px;
  padding-bottom: 60px;

  @media (max-width: 1099.99px) {
    flex-wrap: wrap;
  }

  @media (min-width: 1100px) {
  }

  .card-metrica {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 50px;

    .card-top {
      display: flex;
      vertical-align: middle;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 200px;
      animation: fade-in-init-general 1s;
    }

    .card-bottom {
      animation: fade-in-init-general 1.5s;

      .title {
        font-family: $lato;
        font-weight: bold;
        text-align: center;
        color: $blanco;

        @media (min-width: 1100px) {
          font-size: 48px;
        }

        @media (max-width: 1099.99px) {
          font-size: 34px;
        }
      }

      .paragraph {
        font-family: $lato;
        font-weight: 500;
        color: $blanco;
        text-align: center;
        margin-top: 12px;

        @media (min-width: 1100px) {
          font-size: 22px;
        }

        @media (max-width: 1099.99px) {
          font-size: 20px;
        }
      }
    }
  }
}

.map {
  width: 100%;
  position: relative;
  overflow-y: hidden;

  .content-map-contact {
    position: relative;
    z-index: 99999;
    width: 100%;
    min-height: 500px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;

    .move-contact-teamclass {
      transform: translateX(-200%);
      transition: transform 1s ease-in-out;
    }

    .move-no-contact-teamclass {
      transform: translateX(0);
      transition: transform 1s ease-in-out;
    }

    .forms-contact {
      @media (min-width: 1300px) {
        width: 50%;
      }

      @media (min-width: 1000px) and (max-width: 1299.99px) {
        width: 60%;
      }

      @media (min-width: 700px) and (max-width: 999.99px) {
        width: 70%;
      }

      @media (max-width: 699.99px) {
        width: 95%;
      }

      margin-top: 55px;
      margin-bottom: 28px;
      background-color: $morado;
      padding-top: 37px;
      padding-left: 46px;
      padding-right: 46px;
      padding-bottom: 43px;
      border-radius: 29px;
      box-shadow: 0px 0px 10px #00000033;
      position: relative;

      .contact {
        @media (min-width: 700px) {
          width: 90%;
        }

        @media (max-width: 699.99px) {
          width: 100%;
        }

        margin-left: auto;
        margin-right: auto;
      }

      .title-section {
        @media (max-width: 799.99px) {
          padding-bottom: 30px;
        }
      }

      p {
        width: 90%;
        font-size: 16px;
        font-family: $lato;
        color: $blanco;
        padding-top: 10px;
        padding-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
      }

      .exclamacion-img {
        position: absolute;

        @media (min-width: 1300px) {
          right: 15%;
        }

        @media (min-width: 1100px) and (max-width: 1299.99px) {
          right: 20%;
        }

        @media (max-width: 1099.99px) {
          right: 16%;
        }

        @media (min-width: 800px) {
          top: 40px;
        }

        @media (max-width: 799.99px) {
          top: 20px;
        }
      }
    }

    .forms-sucursals {
      background-color: $morado;
      border-radius: 30px;
      margin-bottom: 28px;
      padding-left: 46px;
      padding-right: 46px;
      padding-bottom: 34px;
      padding-top: 34px;
      box-shadow: 0px 0px 10px #00000033;
      display: flex;
      align-items: center;

      @media (min-width: 1300px) {
        width: 50%;
      }

      @media (min-width: 1000px) and (max-width: 1299.99px) {
        width: 60%;
      }

      @media (min-width: 700px) and (max-width: 999.99px) {
        width: 70%;
      }

      @media (max-width: 699.99px) {
        width: 95%;
      }

      label {
        font-family: $lato;
        font-size: 22px;
        color: $blanco;
      }

      .box-sucursales {
        width: 60%;
        margin-left: 50px;

        .button-no-select {
          color: $miel;

          &:hover {
            border: 2px solid $miel;
            transition: border 0.3s ease-in-out;
          }
        }

        .button-select {
          background-color: $miel;
          color: $morado;
        }

        button {
          background: transparent;
          font-family: $roboto;
          font-weight: bold;
          font-size: 16px;
          cursor: pointer;
          border: 2px solid transparent;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 15px;
          padding-right: 15px;
          transition: border 0.3s ease-in-out;
          border-radius: 25px;
        }
      }
    }

    .move-infosucursal-teamclass {
      transform: translateX(0);
      transition: transform 1s ease-in-out;
    }

    .move-no-infosucursal-teamclass {
      transform: translateX(120%);
      transition: transform 1s ease-in-out;
    }

    .forms-data-sucursal {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: $blanco;
      box-shadow: 0px 0px 10px #00000033;
      border-radius: 30px;
      border: 1px solid #d7d7d7;

      @media (max-width: 849.99px) {
        width: calc(100% - 20px);
        padding-top: 15px;
        padding-bottom: 15px;
      }

      @media (min-width: 850px) {
        width: 350px;
        min-height: 300px;
        padding-top: 30px;
        padding-bottom: 30px;
      }

      .container-info-sucursal {
        display: flex;
        vertical-align: middle;
        align-items: center;

        @media (min-width: 850px) {
          flex-wrap: wrap;
        }
      }

      .info-top {
        @media (max-width: 849.99px) {
          width: 60%;
        }

        @media (min-width: 850px) {
          width: 100%;
        }
      }

      .info-bottom {
        @media (max-width: 849.99px) {
          width: 40%;
        }

        @media (min-width: 850px) {
          width: 100%;
        }
      }

      .info-sucursal {
        display: flex;
        vertical-align: middle;
        align-items: center;
        width: 85%;
        margin-left: auto;
        margin-right: auto;

        .title {
          color: $morado;
          font-size: 20px;
          font-weight: bold;
          font-family: $lato;
        }

        img {
          width: 30px;
          margin-right: 15px;
        }

        label {
          font-size: 16px;
          font-family: $lato;
          color: $negro;
        }

        margin-bottom: 15px;
      }

      .suc-casa-matriz {
        background-image: url(../../images/jpg/suc-la-concepcion.jpg);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      .glyphicon-sucursal {
        margin-left: auto;
        margin-right: auto;
        border-radius: 20px;

        @media (max-width: 849.99px) {
          width: 90%;
          min-height: 200px;
        }

        @media (min-width: 850px) {
          width: 85%;
          min-height: 300px;
        }
      }
    }
  }

  .shadow-box-map {
    position: absolute;
    height: 230px;
    width: 100%;
    background: linear-gradient(180deg, #21005200, #21005280);
    z-index: 9999;
    bottom: 0;
  }

  #map {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 999;
  }
}

.clientes-testimonios {
  padding-top: 90px;
  padding-bottom: 109px;
  position: relative;

  .flecha-curva {
    position: absolute;

    @media (min-width: 1400px) {
      transform: scaleX(-1) rotate(110deg);
      top: 140px;
      right: 7%;
    }

    @media (max-width: 1399.99px) {
      display: none;
    }
  }

  .pipeline-testimonios {
    position: absolute;
    border-bottom: 30px solid $miel;
    border-top: 30px solid $miel;
    border-right: 30px solid $miel;

    @media (min-width: 1200px) {
      width: 20%;
      bottom: 109px;
      height: 251px;
      border-top-right-radius: 49px;
      border-bottom-right-radius: 49px;
    }

    @media (max-width: 1199.99px) {
      width: 24%;
      height: 441px;
      top: 23%;
      border-top-right-radius: 65px;
      border-bottom-right-radius: 65px;
    }
  }

  .carrousel-testimonios {
    @media (min-width: 1200px) {
      min-height: 350px;
    }

    @media (max-width: 1199.99px) {
      min-height: 750px;
    }

    margin-top: 24px;
    margin-bottom: 62px;
    overflow: hidden;
    position: relative;

    @for $index from -10 to 10 {
      @if $index < 0 {
        .card-cliente-left-#{$index * (-1)} {
          left: 100% * $index;
          transition: left 0.5s ease-in-out;
        }
      }

      @if $index==0 {
        .card-cliente-principal {
          left: 0;
          transition: left 0.5s ease-in-out;
        }
      }

      @if $index>0 {
        .card-cliente-right-#{$index} {
          left: 100% * $index;
          transition: left 0.5s ease-in-out;
        }
      }
    }

    .card-clientes-simple {
      .card-cliente {
        .lineas-card {
          @media (max-width: 1199.99px) {
            display: none;
          }

          @media (min-width: 1200px) {
            position: absolute;
            width: 85px;
            height: 57px;
            top: -10%;
            left: -2%;
          }
        }
      }
    }

    .card-clientes-logo {
      .card-cliente {
        .lineas-card {
          position: absolute;

          @media (max-width: 1199.99px) {
            width: 113px;
            height: 76px;
            top: -10%;
            right: 0;
            transform: rotate(130deg);
          }

          @media (min-width: 1200px) {
            width: 85px;
            height: 57px;
            top: -10%;
            left: -2%;
          }
        }
      }
    }

    .card-clientes-testiminios {
      width: 100%;
      position: absolute;
      height: 100%;
      display: flex;
      vertical-align: middle;
      justify-content: center;
      align-items: center;

      .card-cliente {
        width: 80%;
        position: relative;
        background-color: $verdeLima;
        padding-top: 59px;
        display: flex;
        vertical-align: middle;
        align-items: center;
        justify-content: center;

        @media (min-width: 1200px) {
          height: 218px;
          border-radius: 109px;
          padding-bottom: 59px;
        }

        @media (max-width: 1199.99px) {
          height: 484px;
          border-radius: 40px;
          padding-bottom: 30px;
          flex-direction: column;
        }

        .left-card {
          @media (min-width: 1200px) {
            width: 218px;
            height: 218px;
          }

          @media (max-width: 1199.99px) {
            width: 213px;
            height: 213px;
          }

          border-radius: 50%;
          background-color: $blanco;
          box-shadow: 0px 3px 10px #00000040;
          display: flex;
          vertical-align: middle;
          align-items: center;
          justify-content: center;
          position: relative;

          @media (max-width: 1199.99px) {
            position: absolute;
            top: -106px;
          }

          img {
            width: 90%;
          }
        }

        .right-card {
          @media (min-width: 1200px) {
            width: calc(100% - 218px);
            padding-right: 109px;
            padding-left: 44px;
          }

          @media (max-width: 1199.99px) {
            width: calc(100% - 60px);
          }

          h3 {
            text-transform: uppercase;
            color: $blanco;
            font-family: $lato;
            font-weight: bold;
          }

          label {
            color: $blanco;
            font-family: $lato;
            font-weight: bold;
            padding-bottom: 15px;
            display: flex;
          }

          p {
            color: $blanco;
            font-family: $lato;
            font-weight: 500;
            font-size: 16px;
          }
        }

        .amplificador-card {
          @media (min-width: 1200px) {
            position: absolute;
            width: 128px;
            height: 172px;
            bottom: -22%;
            right: -3%;
          }

          @media (max-width: 1199.99px) {
            display: none;
          }
        }
      }
    }
  }

  .circles-carrousel {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1200px) {
      bottom: 0;
    }

    @media (max-width: 1199.99px) {
      bottom: 10%;
    }

    .circle-selected {
      background-color: $miel;
    }

    .circle-notselected {
      background-color: #b7a0d9;
      cursor: pointer;
    }

    .item-carrousel {
      width: 21px;
      height: 21px;
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: 9px;
      }
    }
  }
}

.data-ref-contact {
  width: 100%;

  .data-ref {
    width: 100%;
    display: flex;
    vertical-align: middle;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;

    input[type="text"] {
      top: 0;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      padding-left: 60px;
      padding-right: 20px;
      border-radius: 26px;
      border: 1px solid $grisd7;
      font-family: $roboto;
      font-size: 16px;
      outline: none;
      color: $morado;

      &::placeholder {
        color: #d7d7d7;
      }
    }

    svg {
      position: relative;
      z-index: 9999;
      margin-left: 20px;
      width: 24px;
      color: #d7d7d7;
    }

    textarea {
      border: 1px solid $grisd7;
      font-family: $roboto;
      font-size: 16px;
      outline: none;
      width: 100%;
      resize: none;
      height: 110px;
      border-radius: 26px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 20px;
      padding-left: 20px;
      margin-top: -10px;
      margin-bottom: -10px;
      color: $morado;

      &::placeholder {
        color: $grisd7;
      }
    }

    button {
      font-family: $livvic;
      width: 100%;
      font-weight: bold;
      font-size: 18px;
      text-transform: uppercase;
      padding-top: 14px;
      padding-bottom: 14px;
      border-radius: 29px;

      &:not(:disabled) {
        color: $blanco;
        background-color: $rojoImperial;
        cursor: pointer;
      }

      &:disabled {
        background-color: $grisd7;
        color: $gris64;
      }
    }

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}

.conocenos-mas {
  width: 100%;
  padding-bottom: 150px;

  h2 {
    margin: 30px auto;
  }

  .top {
    width: 100%;
    height: 400px;
    position: relative;

    .arrow-left,
    .arrow-right {
      height: 100%;
      position: absolute;
      z-index: 99;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        color: rgba($blanco, 0.5);
        margin-top: calc((400px - 100px) / 2);

        &:hover {
          cursor: pointer;
        }

        svg {
          font-size: 4rem;
          filter: drop-shadow(10px, 10px, 10px, red);
        }
      }
    }

    .arrow-left {
      background: $morado;
      width: 60px;
      height: 100%;
      top: 0;
      left: -3px;
    }

    .arrow-right {
      right: 0;
    }

    .enabled {
      svg {
        color: $miel;

        &:hover {
          color: $rojoImperial;
          cursor: pointer;
        }
      }
    }

    .instagram-posts {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 10;
      display: flex;
      margin-right: 10px;
      padding-left: 60px;
      padding-top: 10px;
      overflow: hidden;

      .ig-post {
        width: 280px;
        margin-right: 20px;
        transition: all 0.5s ease;

        &:last-child {
          min-width: 560px;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          text-align: center;

          p {
            font-family: $lato;
            color: $blanco;
            font-size: 1.8rem;
            padding: 0 15px;
            font-weight: 600;
          }

          .image {
            position: absolute;
          }

          .arrow {
            right: 0;
            margin-bottom: -140px;
          }

          .start-one {
            bottom: 50px;
            margin-right: 120px;
          }

          .start-two {
            bottom: 90px;
            margin-left: 100px;
          }
        }

        .ig-post-content {
          width: 280px;
          height: 280px;
          overflow: hidden;
          border-radius: 30px;
          box-shadow: 0 0 10px 0 #222;
          transition: all 0.5s ease;
          user-select: none;
          position: relative;

          .media-type {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 0;

            svg {
              color: $blanco;
            }
          }

          img {
            width: 100%;
          }
        }

        .first-post {
          margin-top: 40px;
        }

        .second-post {
          margin-top: 100px;
        }

        .third-post {
          margin-top: 60px;
        }

        .fourth-post {
          margin-top: 0px;
        }

        .fifth-post {
          margin-top: 70px;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ig-btn {
      background: $rojoImperial;
      padding: 15px 160px;
      border-radius: 29px;
      font-family: $livvic;
      font-weight: 600;
      font-size: 1rem;
      color: $blanco;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      margin-top: 30px;

      svg {
        margin-right: 5px;
      }

      @media (max-width: 900px) {
        padding: 15px 100px;
      }
    }
  }
}

.top-arma-tu-curso {
  width: 100%;
  min-height: 300px;
  padding-top: 130px;
  padding-bottom: 100px;
  overflow: hidden;

  @media (max-width: 1090px) {
    padding-top: 170px;
  }

  .top-container {
    display: flex;

    .arrow {
      position: relative;

      img {
        width: 100%;
      }
    }

    .left {
      width: 10%;
      height: 300px;
      padding: 10px 0;
      position: relative;

      .yellow-line {
        width: 1000px;
        height: 1000px;
        padding: 20px;
        border-radius: 49px;
        border: 30px solid $miel;
        position: absolute;
        top: -880px;
        left: -650px;

        @media (max-width: 1150px) {
          left: -700px;
        }

        @media (max-width: 1090px) {
          left: -750px;
        }

        @media (max-width: 950px) {
          left: -800px;
        }
      }

      .arrow.left {
        width: 200px;
        position: absolute;
        left: -15px;

        img {
          position: absolute;
          bottom: -60px;
        }

        @media (max-width: 950px) {
          width: 150px;
          bottom: 30px;
        }
      }
    }

    .middle {
      width: 80%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $blanco;
      font-family: $lato;

      .arma-tu-curso-title {
        width: 450px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .bajada {
          color: $miel;
          font-weight: bold;
          font-size: 1.5rem;
          margin-top: 14px;
        }

        .container-gotas {
          width: 60px;
          position: absolute;
          left: 10px;
          top: -30px;

          img {
            width: 100%;
          }
        }
      }

      .arma-tu-curso-descripcion {
        width: 80%;
        padding: 0 120px;
        margin-top: 80px;
        text-align: center;

        @media (max-width: 1150px) {
          padding: 0 90px;
        }

        @media (max-width: 1090px) {
          padding: 0 60px;
        }
      }
    }

    .right {
      width: 10%;
      position: relative;

      img {
        width: 100%;
      }

      .arrow.right {
        width: 200px;
        transform: scaleX(-1);
        position: absolute;
        bottom: -60px;
        right: 0;

        @media (max-width: 950px) {
          width: 150px;
          bottom: -30px;
        }
      }

      .certificado-container {
        width: 170px;
        position: absolute;
        left: -190px;
        top: 10px;

        @media (max-width: 1090px) {
          left: -130px;
        }

        @media (max-width: 950px) {
          left: -100px;

          & > img {
            opacity: 0;
          }
        }
      }

      .puzzle-container {
        width: 80px;
        position: absolute;
        top: -30px;
        left: -70px;
      }
    }
  }
}

.mejora-la-experiencia {
  width: 100%;
  min-height: 300px;
  padding: 10px 0;

  .bajada {
    color: $blanco;
    text-align: center;
    margin-top: 5px;
    font-weight: bold;
    font-family: $lato;
    font-size: 1.5rem;
  }

  .items-container {
    width: 90%;
    min-height: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding-bottom: 53px;
    position: relative;
    // left: 50%;
    // transform: translateX(-50%);

    // .items-content {
    //   width: 80%;
    //   padding: 10px;
    //   align-self: flex-start;
    //   position: relative;
    //   background: olive;
    //   left: 50%;
    //   transform: translateX(-50%);
    // }

    $arrayExperienciaPosition: "icon-experiencia-cafe" 0 0,
      "icon-experiencia-terreno" 195px 20px,
      "icon-experiencia-diploma" 100px 200px,
      "icon-experiencia-terreno1" 0 390px, "icon-experiencia-cafe1" 195px 370px,
      "icon-experiencia-cafe2" 100px 580px, "icon-experiencia-cafe3" 0 760px,
      "icon-experiencia-diploma1" 195px 790px,
      "icon-experiencia-terreno2" 100px 970px;

    @each $class, $top, $left in $arrayExperienciaPosition {
      .#{$class} {
        position: absolute;
        top: $top;
        left: $left;
      }
    }

    .item {
      width: 189px;
      height: 189px;
      border-radius: 50%;
      border: 1px solid $blanco;
      display: flex;
      justify-content: center;
      align-items: center;
      vertical-align: middle;

      &:hover {
        background: $blanco;
        transition: background-color 0.3s ease-in-out;
      }

      &:not(:hover) {
        background-color: $morado;
        transition: background-color 0.3s ease-in-out;
      }

      &:not(:hover) > .item-text {
        display: none;
        transition: all 1s ease-in-out;
      }

      &:hover > .item-text {
        display: flex;
        transition: all 1s ease-in-out;
      }

      &:not(:hover) > .item-image {
        display: flex;
        transition: all 1s ease-in-out;
      }

      &:hover > .item-image {
        display: none;
        transition: all 1s ease-in-out;
      }

      .item-image {
        width: 50%;

        img {
          width: 100%;
        }
      }

      .item-text {
        font-family: $lato;
        font-weight: bold;
        font-size: 1.5rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
      }
    }
  }
}

.simula-tu-curso {
  width: 100%;
  min-height: 300px;
  padding: 10px;
  padding-top: 96px;
  margin-bottom: 96px;

  .cuestionario-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    padding: 50px 40px;
    background: $morado;
    border: 1px solid $blanco;
    border-radius: 40px;
    display: flex;
    flex-direction: column;

    .top {
      .barra-progreso {
        .etapas {
          display: flex;
          justify-content: space-between;
          position: relative;

          .etapa {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: #b7a0d9;
            z-index: 20;
          }

          .actual {
            background: $verdeLima;
          }

          .progress-line {
            width: 98%;
            height: 8px;
            background: #b7a0d9;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
          }
        }
      }
    }

    .middle {
      .descripcion-content {
        padding: 10px;
        color: $blanco;
        font-family: $lato;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;

        .pregunta {
          padding-top: 43px;
          padding-bottom: 43px;
          font-size: 22px;
          font-family: $lato;
        }

        .descripcion-box {
          width: 100%;
          padding: 10px;

          .descripcion {
            width: 100%;
            min-height: 200px;
            resize: none;
            background: $morado;
            border-top: 1px solid $blanco;
            font-family: $lato;
            color: $blanco;
            padding-top: 20px;
            padding-bottom: 20px;

            &:focus {
              outline: none;
            }

            &::placeholder {
              color: $blanco;
            }
          }
        }
      }

      .participantes-duracion-content {
        font-family: $lato;
        color: $blanco;
        font-weight: bold;
        font-size: 1.375rem;
        margin-top: 51px;

        .title {
          font-family: $lato;
          font-size: 22px;
          font-weight: bold;
        }

        .participantes,
        .duracion {
          display: flex;
          padding-top: 25px;
          padding-bottom: 64px;

          .btn-radio {
            font-size: 22px;
            cursor: pointer;

            svg {
              margin-right: 10px;
            }

            &:not(:last-child) {
              padding-right: 40px;
            }
          }
        }
      }

      .modalidad-ubicacion-content {
        padding-top: 43px;
        padding-bottom: 43px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $blanco;
        font-family: $lato;
        font-weight: bold;
        min-height: 400px;

        .div-container-modalidad {
          position: relative;
        }

        .title {
          font-family: $lato;
          font-size: 22px;
          font-weight: bold;
        }

        .title-shadow {
          color: rgb(157, 125, 203);
        }

        .presencial {
          font-weight: 500;
        }

        .opciones {
          padding-top: 10px;
          padding-bottom: 20px;

          .btn-normal {
            font-family: $lato;
            font-size: 18px;
            padding: 14px 55px;
            border-radius: 30px;
            font-weight: bold;
            border: 2px solid $blanco;
            box-shadow: 0px 0px 10px #0000004d;

            &:not(:last-child) {
              margin-right: 15px;
            }

            &:not(:disabled) {
              background: transparent;
              color: $blanco;
              cursor: pointer;
            }

            &:disabled {
              color: $morado;
              background-color: $blanco;
            }
          }
        }

        .opciones-shadow {
          .btn-normal {
            border: 2px solid rgb(157, 125, 203);

            &:not(:disabled) {
              background: transparent;
              color: rgb(157, 125, 203);
            }

            &:disabled {
              background-color: rgb(157, 125, 203);
            }
          }
        }
      }

      .competencias-content {
        display: flex;
        flex-direction: column;
        padding-top: 43px;
        padding-bottom: 43px;

        .pregunta {
          width: 100%;
          color: $blanco;
          font-family: $lato;
          text-align: center;
          font-weight: bold;
          font-size: 22px;
          padding-bottom: 10px;
        }

        .competencias {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          .btn-normal {
            font-family: $lato;
            font-size: 18px;
            padding: 14px 19px;
            border-radius: 30px;
            font-weight: bold;
            border: 2px solid $blanco;
            box-shadow: 0px 0px 10px #0000004d;
            margin-bottom: 10px;

            &:not(:last-child) {
              margin-right: 15px;
            }
          }

          .btn-add-skills {
            background: transparent;
            color: $blanco;
          }

          .btn-remove-skills {
            color: $morado;
            background-color: $blanco;
          }
        }
      }

      .resumen-content {
        width: 100%;
        display: flex;
        padding-top: 30px;

        .resumen-left {
          width: 50%;
          padding-right: 30px;
          color: $blanco;
          font-family: $lato;

          h4 {
            display: flex;
            vertical-align: middle;
            font-size: 30px;
            padding-bottom: 30px;

            svg {
              margin-left: 10px;
            }
          }

          .descripcion {
            .descripcion-titulo {
              font-weight: bold;
            }
          }

          .respuestas {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-top: 30px;

            .middle {
              border: 1px solid $blanco;
              width: 49%;
              font-family: $lato;
              border-radius: 10px;
              padding-top: 10px;
              padding-bottom: 10px;
              margin-bottom: 10px;
              display: flex;
              vertical-align: middle;
              align-items: center;
              justify-content: center;

              svg {
                margin-right: 10px;
              }
            }

            .large {
              width: 100%;
              border: 1px solid $blanco;
              font-family: $lato;
              border-radius: 10px;
              padding-top: 10px;
              padding-bottom: 10px;
              margin-bottom: 10px;
              display: flex;
              vertical-align: middle;
              align-items: center;
              justify-content: center;

              svg {
                margin-right: 10px;
              }
            }
          }

          .habilidades {
            padding: 10px;
            display: flex;
            flex-wrap: wrap;

            .skill-item {
              padding: 10px 30px;
              font-family: $lato;
              border-radius: 30px;
              font-size: 14px;
              background-color: $blanco;
              color: $morado;
              font-weight: bold;
              margin-bottom: 10px;

              &:not(:last-child) {
                margin-right: 5px;
              }
            }
          }

          .btn-edit {
            background: transparent;
            color: $miel;
            cursor: pointer;
          }
        }

        .contacto-right {
          width: 50%;
          font-family: $lato;
          color: $blanco;
          padding-left: 30px;

          .contacto-titulo {
            font-weight: bold;
            font-size: 2rem;
          }

          .contacto {
            margin-top: 30px;
            .data-ref-contact {
              margin-bottom: 10px;
            }
          }

          .input-check {
            margin-top: 20px;

            .check-input {
              display: flex;
              vertical-align: middle;
              align-items: center;
              font-family: $lato;
              font-size: 16px;
              background-color: transparent;
              color: $blanco;
              cursor: pointer;

              svg {
                margin-right: 10px;
                color: $miel;
              }
            }

            &:last-child {
              margin-bottom: 40px;
            }
          }
        }

        .vertical-divider {
          width: 1px;
          height: 300px;
          background: rgba($blanco, 0.5);
          align-self: center;
        }
      }

      .agradecimientos-content {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .agradecimientos-texto {
          font-family: $lato;
          color: $blanco;
          font-size: 2rem;
          font-weight: bold;
          margin-top: 37px;
          margin-bottom: 95px;
          text-align: center;
        }
      }
    }

    .bottom {
      display: flex;

      .left {
        width: 50%;
      }

      .right {
        width: 50%;
        justify-content: right;
        display: flex;
      }
    }
  }

  .btn-cuestionario {
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 44px;
    font-weight: bold;
    font-size: 1.125rem;
    font-family: $lato;

    &:not(:disabled) {
      &:hover {
        cursor: pointer;
      }
    }

    &:disabled {
      background-color: rgb(220, 220, 220);
      color: rgb(150, 150, 150);
    }
  }

  .btn-back {
    background: $blanco;
    color: $rojoImperial;
  }

  .btn-next {
    &:not(:disabled) {
      color: $blanco;
      background: linear-gradient(180deg, #ef3340, #db222f);
    }
  }

  .button-finish {
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 44px;
    font-weight: bold;
    font-size: 1.125rem;
    font-family: $lato;
    width: calc(100% - 30px);

    &:disabled {
      background-color: rgb(220, 220, 220);
      color: rgb(150, 150, 150);
    }

    &:not(:disabled) {
      cursor: pointer;
      color: $negro;
      background-color: $miel;
    }
  }

  .disabled {
    color: $blanco;
    background: #b7a0d9;
  }

  .btn-radio {
    background: transparent;
    font-weight: bold;
    color: $blanco;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;

    svg {
      color: $miel;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-init-general {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
