@import "config";

.footer {
  min-height: 300px;
  width: 100%;
  position: relative;
  bottom: 0;
  background-color: $blanco;

  .f-top {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: 800px) {
      flex-direction: column;
    }

    .f-top-left {
      @media (min-width: 800px) {
        width: 300px;
      }

      @media (max-width: 800px) {
        width: 100%;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 20px;

      img {
        width: 300px;
      }

      label {
        font-family: $lato;
        font-size: 1.35rem;
        color: $morado;
        font-weight: 500;
        margin-top: -20px;
        display: flex;
      }

      .rrss-box {
        width: 80%;
        min-height: 60px;
        margin-top: 20px;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;

        $arrayRRSS: "instagram" "IG.svg", "facebook" "FB.svg",
          "linkedin" "Linkedin.svg", "tiktok" "tiktok.svg";

        @each $class, $url in $arrayRRSS {
          .#{$class} {
            background-image: url(../../images/svg/#{$url});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
          }
        }

        .item-rrss {
          width: 42px;
          height: 42px;
          background-color: $rojoImperial;
          display: inline-flex;
          border-radius: 50%;
          cursor: pointer;

          &:not(:last-child) {
            margin-right: 52px;
          }
        }
      }
    }

    .f-top-right {
      display: flex;
      vertical-align: middle;
      justify-content: flex-end;
      padding-top: 80px;

      @media (min-width: 800px) {
        width: calc(100% - 300px);
      }

      @media (max-width: 800px) {
        width: 100%;
      }

      @media (max-width: 1300px) {
        .list-sitemap-left,
        .list-sitemap-right {
          display: flex;
          flex-direction: column;
          align-items: center;

          @media (max-width: 1100px) {
            width: 50%;
          }

          @media (min-width: 1100px) and (max-width: 1300px) {
            width: 30%;
          }

          .item-map-site {
            margin-bottom: 25px;
          }
        }
      }

      .list-sitemap-left {
        margin-right: 70px;
      }

      .item-map-site {
        font-family: $lato;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 500;
        color: $morado;

        &:not(:hover) {
          color: $morado;
          transition: color 0.2s ease-in-out;
        }

        &:hover {
          color: $rojoImperial;
          transition: color 0.2s ease-in-out;
        }

        @media (min-width: 1300px) {
          &:not(:last-child) {
            margin-right: 70px;
          }
        }
      }
    }
  }

  .clear-box {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 3px solid rgba($morado, 0.1);
    height: 1px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .f-bottom {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
    display: flex;
    vertical-align: middle;
    align-items: center;

    @media (max-width: 1100px) {
      flex-direction: column;
    }

    img {
      display: inline-flex;
      margin-right: 10px;
    }

    .data-contact-footer {
      display: flex;
      vertical-align: middle;
      align-items: center;

      @media (max-width: 1100px) {
        justify-content: center;
        align-content: center;
        margin-bottom: 30px;
      }
    }

    .label-cm,
    .label-ubicacion,
    .label-email,
    .label-phone {
      font-family: $lato;
      font-size: 1rem;
      color: $morado;
    }

    @media (max-width: 1100px) {
      .label-cm {
        margin-bottom: 31px;
        font-weight: bold;
      }
    }

    @media (min-width: 1100px) {
      .label-cm {
        padding-right: 30px;
      }

      .label-ubicacion,
      .label-email,
      .label-phone {
        padding-right: 70px;
      }
    }
  }
}
