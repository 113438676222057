@import "config";

.front-page-cursos {
  @media (max-width: 1089.99px) {
    padding-top: 150px;
  }

  @media (min-width: 1090px) {
    padding-top: 80px;
  }

  width: 100%;
  position: relative;
  min-height: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .front-cursos-content {
    @media (max-width: 599.99px) {
      width: 90%;
    }

    @media (min-width: 600px) and (max-width: 699.99px) {
      width: 80%;
    }

    @media (min-width: 700px) and (max-width: 799.99px) {
      width: 70%;
    }

    @media (min-width: 800px) and (max-width: 899.99px) {
      width: 60%;
    }

    @media (min-width: 900px) {
      width: 60%;
    }

    @media (max-width: 1049.99px) {
      padding-top: 40px;
    }

    @media (min-width: 1050px) {
      padding-top: 80px;
    }

    display: flex;
    vertical-align: middle;
    justify-content: center;
    flex-direction: column;

    .title {
      font-family: $lato;
      font-weight: bold;
      color: $blanco;
      text-align: center;
      position: relative;
      animation: fade-in-init-escuelas 1s;

      @media (max-width: 899.99px) {
        font-size: 40px;
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 15px;
      }

      @media (min-width: 900px) and (max-width: 1049.99px) {
        font-size: 50px;
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 15px;
      }

      @media (min-width: 1050px) {
        font-size: 64px;
        line-height: 77px;
      }

      .icon-lineas-responsive {
        position: absolute;
        left: 0;
        top: -10px;
        width: 60px;
        height: 40px;
        animation: fade-in-init-escuelas 1s;

        @media (min-width: 1050px) {
          display: none;
        }
      }

      .icon-avion-responsive {
        position: absolute;
        right: 0;
        top: -60px;
        width: 101px;
        height: 73px;
        animation: fade-in-init-escuelas 1s;

        @media (min-width: 1050px) {
          display: none;
        }
      }
    }

    .subtitle {
      color: $miel;
      text-align: center;
      padding-top: 25px;
      padding-bottom: 15px;
      font-weight: bold;
      font-family: $lato;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      animation: fade-in-init-escuelas 1s;

      @media (max-width: 1049.99px) {
        font-size: 20px;
      }

      @media (min-width: 1050px) {
        font-size: 30px;
      }
    }

    .img-line {
      margin-left: auto;
      margin-right: auto;
      animation: fade-in-init-escuelas 1s;

      @media (max-width: 1049.99px) {
        width: 85%;
      }

      @media (min-width: 1050px) {
        width: 60%;
      }
    }

    .paragraph {
      font-family: $lato;
      color: $blanco;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      padding-top: 33px;
      animation: fade-in-init-escuelas 1s;

      .button-link-download {
        display: inline;
        background-color: transparent;
        cursor: pointer;
        text-decoration: underline;
        color: $miel;
      }

      @media (max-width: 1049.99px) {
        font-size: 18px;
        width: 95%;

        .button-link-download {
          font-size: 18px;
        }
      }

      @media (min-width: 1050px) {
        font-size: 22px;
        width: 80%;

        .button-link-download {
          font-size: 22px;
        }
      }
    }

    .button-download {
      background: linear-gradient(180deg, #ef3340, #db222f);
      padding-top: 17px;
      padding-bottom: 17px;
      display: flex;
      vertical-align: middle;
      justify-content: center;
      font-family: $lato;
      color: $blanco;
      font-weight: bold;
      box-shadow: 0px 0px 10px #0000004d;
      border-radius: 30px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;

      @media (max-width: 1049.99px) {
        width: 90%;
      }

      @media (min-width: 1050px) {
        width: 80%;
      }
    }
  }

  .icons-left {
    width: 20%;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1049.99px) {
      display: none;
    }

    .icon-pc {
      width: 153px;
      height: 100px;
      margin-top: 40px;
      animation: fade-in-init-escuelas-icons 2s;
    }

    .icon-estrellas {
      width: 104px;
      height: 66px;
      margin-top: 41px;
      animation: fade-in-init-escuelas-icons 2s;
    }

    .icon-mouse {
      width: 165px;
      height: 126px;
      margin-top: 5px;
      margin-left: 70px;
      animation: fade-in-init-escuelas-icons 2s;
    }
  }

  .icons-right {
    width: 20%;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    flex-direction: column;
    position: relative;

    @media (max-width: 1049.99px) {
      display: none;
    }

    .icon-lapiz {
      width: 75px;
      height: 68px;
      margin-top: 50px;
      animation: fade-in-init-escuelas-icons 2s;
    }

    .icon-avion {
      width: 103px;
      height: 75px;
      margin-top: -20px;
      margin-left: 70px;
      animation: fade-in-init-escuelas-icons 2s;
    }

    .icon-lineas {
      width: 65px;
      height: 50px;
      margin-left: -20px;
      margin-top: 27px;
      animation: fade-in-init-escuelas-icons 2s;
    }

    .icon-cuaderno {
      width: 138px;
      height: 136px;
      margin-top: -20px;
      animation: fade-in-init-escuelas-icons 2s;
    }
  }
}

.grid-escuelas {
  padding-top: 70px;
  padding-bottom: 135px;

  .title-grid {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: $blanco;
    font-family: $lato;
    font-size: 30px;
    font-weight: bold;
    animation: fade-in-init-escuelas-cards 2s;
  }

  .grid-items {
    @media (min-width: 1400px) {
      width: 90%;
    }

    @media (min-width: 1200px) and (max-width: 1399.99px) {
      width: 70%;
    }

    @media (min-width: 900px) and (max-width: 1199.99px) {
      width: 90%;
    }

    @media (max-width: 899.99px) {
      width: 95%;
    }

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    padding-top: 38px;

    .item-grid {
      animation: fade-in-init-escuelas-cards 2s;
      @media (min-width: 1400px) {
        width: calc(100% / 3);
      }

      @media (min-width: 900px) and (max-width: 1399.99px) {
        width: calc(100% / 2);
      }

      @media (max-width: 899.99px) {
        width: 100%;
      }

      display: flex;
      vertical-align: middle;
      align-items: center;
      justify-content: center;
      padding-top: 15px;

      .card-grid-item {
        width: 95%;
        background-color: #eee3ff;
        min-height: 150px;
        border-radius: 26px;
        display: flex;
        vertical-align: middle;
        align-items: center;
        justify-content: center;

        $escuelas-grid-icons: "escuela-liderazgo" "liderazgo-apagado.svg"
            "liderazgo-encendido.svg",
          "escuela-diversidad" "diversidad-apagado.svg"
            "diversidad-encendido.svg",
          "escuela-ventas" "ventas-apagado.svg" "ventas-encendido.svg",
          "escuela-food" "food-apagado.svg" "food-encendido.svg",
          "escuela-precontrato" "precontrato-apagado.svg"
            "precontrato-encendido.svg",
          "escuela-bienestar" "bienestar-apagado.svg" "bienestar-encendido.svg";

        @each $class, $url, $hover in $escuelas-grid-icons {
          &:not(:hover) {
            .#{$class} {
              background-image: url(../../images/svg/cursos/#{$url});
              background-position: center;
              background-repeat: no-repeat;
              background-size: 60%;
              transition: background 0.2s ease-in-out;
            }

            .right-card-grid {
              h3 {
                animation: fade-in-out-title 0.3s,
                  change-fadeout-fontsize-title 0.01s infinite 0.3s;
              }

              p {
                animation: fade-out-cursos 0.3s, display-none 0.1s infinite 0.2s;
                opacity: 0;
              }

              .enlace-more-curso {
                animation: fade-out-cursos 0.3s, display-none 0.1s infinite 0.2s;
                opacity: 0;
              }
            }
          }

          &:hover {
            .#{$class} {
              background-image: url(../../images/svg/cursos/#{$hover});
              background-position: center;
              background-repeat: no-repeat;
              background-size: 60%;
              transition: background 0.2s ease-in-out;
            }

            .right-card-grid {
              h3 {
                animation: fade-in-cursos 0.3s;
              }

              p {
                animation: fade-in-cursos 0.3s;
                opacity: 1;
              }
            }
          }
        }

        .left-card-grid {
          width: 130px;
          min-height: 130px;
        }

        .right-card-grid {
          width: calc(100% - 130px);
          padding-right: 20px;
          padding-left: 10px;

          h3 {
            color: $morado;
            font-family: $lato;
            font-weight: bold;
          }

          p {
            font-size: 14px;
            font-family: $lato;
            color: $negro;
          }

          .enlace-more-curso {
            right: 0;
            float: right;
            display: inline-flex;
            margin-top: 3px;
            font-family: $roboto;
            font-weight: bold;
            text-decoration: none;
            color: $miel;
            font-size: 16px;
            cursor: pointer;
            vertical-align: middle;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}

.front-page-curso-detalle {
  min-height: 500px;
  display: flex;
  vertical-align: middle;
  align-items: flex-end;
  position: relative;
  margin-bottom: 100px;

  @media (max-width: 1289.99px) {
    flex-wrap: wrap;
  }

  .curso-top-responsive {
    z-index: 9999;
    bottom: 0;
    display: flex;
    height: 100%;
    vertical-align: middle;
    align-items: flex-end;

    @media (max-width: 1089.99px) {
      padding-top: 200px;
    }

    @media (min-width: 1090px) and (max-width: 1289.99px) {
      padding-top: 100px;
    }

    @media (max-width: 1289.99px) {
      width: 100%;
      position: relative;
    }

    @media (min-width: 1290px) {
      width: calc(16% + 475px);
      position: absolute;
    }

    .curso-left {
      display: flex;
      align-items: flex-start;

      @media (max-width: 1289.99px) {
        width: 30%;
        justify-content: center;
      }

      @media (min-width: 1290px) {
        width: calc(100% - 475px);
        justify-content: right;
      }

      .link-return-categorias {
        display: flex;
        vertical-align: middle;
        align-items: center;
        color: $miel;
        font-family: $lato;
        font-size: 16px;
        text-decoration: none;
        font-weight: bold;
        cursor: pointer;
        position: absolute;
        top: 80px;
        z-index: 9999;

        @media (max-width: 599.99px) {
          margin-top: 70px;
        }

        @media (min-width: 600px) and (max-width: 1089.99px) {
          margin-top: 100px;
        }

        @media (max-width: 1089.99px) {
          margin-right: 0;
        }

        @media (min-width: 1090px) {
          margin-right: 10%;
          margin-top: 5%;
        }
      }
    }

    .curso-center {
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;

      @media (max-width: 1289.99px) {
        width: 70%;
      }

      @media (min-width: 1290px) {
        width: 475px;
      }

      $arrayEscuelasGlyphicon: "glyphicon-escuela-liderazgo" "liderazgo.png"
          center,
        "glyphicon-escuela-ventas" "ventas.png" center,
        "glyphicon-escuela-food" "food.png" center,
        "glyphicon-escuela-diversidad" "diversidad.png" center,
        "glyphicon-escuela-precontrato" "precontrato.png" center,
        "glyphicon-escuela-bienestar" "bienestar.png" center;

      @each $class, $url, $position in $arrayEscuelasGlyphicon {
        .#{$class} {
          background-image: url(../../images/png/cursos/#{$url});
          background-position: $position;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      .glyphicon-person-curso {
        @media (min-width: 600px) {
          height: 475px;
          width: 100%;
        }

        @media (max-width: 599.99px) {
          height: 350px;
          width: 80%;
          background-position-x: 60%;
        }
      }
    }

    .pipeline-container-responsive {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 999;

      .pipeline-left {
        @media (max-width: 1289.99px) {
          width: 30%;
          bottom: 20%;
        }

        @media (min-width: 1290px) {
          display: none;
        }

        border-top: 30px solid #b7a0d9;
        border-right: 30px solid #b7a0d9;
        height: 45%;
        position: absolute;
        border-top-right-radius: 90px;
      }

      .pipeline-right {
        @media (max-width: 1289.99px) {
          width: calc(70% + 30px);
          bottom: -30px;
        }

        @media (min-width: 1290px) {
          display: none;
        }

        border-bottom: 30px solid #b7a0d9;
        border-left: 30px solid #b7a0d9;
        right: 0;
        height: 30%;
        position: absolute;
        border-bottom-left-radius: 90px;
      }
    }
  }

  .curso-left-clear {
    @media (max-width: 1289.99px) {
      width: 100%;
    }

    @media (min-width: 1290px) {
      width: calc(16% + 475px);
    }
  }

  .curso-right {
    z-index: 999;
    padding-top: 100px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 50px;

    @media (max-width: 799.99px) {
      width: 95%;
    }

    @media (min-width: 800px) and (max-width: 999.99px) {
      width: 80%;
    }

    @media (min-width: 1000px) and (max-width: 1249.99px) {
      width: 70%;
    }

    @media (max-width: 1289.99px) {
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: 1290px) {
      width: calc(100% - (16% + 475px));
    }

    .title {
      padding-top: 50px;
      position: relative;
      color: $blanco;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      font-family: $lato;
      font-weight: bold;

      @media (max-width: 899.99px) {
        text-align: center;
        font-size: 48px;
      }

      @media (min-width: 900px) {
        font-size: 64px;
      }

      img {
        position: absolute;
        width: 64px;
        left: -54px;
        top: 25px;
        transform: rotate(-15deg);
      }
    }

    .paragraph {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      color: $blanco;
      padding-top: 30px;
      font-family: $lato;

      @media (max-width: 899.99px) {
        font-size: 18px;
        text-align: center;
      }

      @media (min-width: 900px) {
        font-size: 22px;
      }
    }

    .box-button {
      position: relative;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 50px;

      .icon-arrow-curva {
        position: absolute;
        right: -50px;
        bottom: 50px;
        transform: scaleX(-1) rotate(40deg);

        @media (max-width: 799.99px) {
          display: none;
        }
      }

      .button-arma-tu-curso {
        width: 100%;
        background: linear-gradient(180deg, #ef3340, #db222f);
        display: flex;
        border-radius: 30px;
        vertical-align: middle;
        justify-content: center;
        padding-top: 18px;
        padding-bottom: 18px;
        font-weight: bold;
        color: $blanco;
        text-decoration: none;
        cursor: pointer;
        font-family: $lato;
        margin-top: 87px;

        @media (max-width: 1249.99px) {
          box-shadow: 0px 0px 13px #0000004d;
        }

        @media (max-width: 899.99px) {
          font-size: 18px;
        }

        @media (min-width: 900px) {
          font-size: 22px;
        }
      }
    }
  }

  .pipeline-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    .pipeline-left {
      @media (max-width: 1289.99px) {
        display: none;
      }

      @media (min-width: 1290px) {
        width: 15%;
        bottom: 20%;
      }

      border-top: 30px solid #b7a0d9;
      border-right: 30px solid #b7a0d9;
      height: 55%;
      position: absolute;
      border-top-right-radius: 90px;
    }

    .pipeline-right {
      @media (max-width: 1289.99px) {
        display: none;
      }

      @media (min-width: 1290px) {
        width: calc(85% + 30px);
        bottom: -30px;
      }

      border-bottom: 30px solid #b7a0d9;
      border-left: 30px solid #b7a0d9;
      right: 0;
      height: 30%;
      position: absolute;
      border-bottom-left-radius: 90px;
    }
  }
}

.content-grid-modalidad {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  flex-direction: column;

  .subtitle {
    color: $blanco;
    text-align: center;
    font-family: $lato;
    font-weight: bold;

    @media (max-width: 799.99px) {
      font-size: 24px;
      width: 70%;
      padding-top: 10px;
    }

    @media (min-width: 800px) {
      font-size: 30px;
    }
  }

  .grid-modalidad {
    padding-top: 47px;
    padding-bottom: 34px;
    width: 90%;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .grid-card-modalidad {
      @media (max-width: 799.99px) {
        width: 100%;
      }

      @media (min-width: 800px) and (max-width: 1089.99px) {
        width: 80%;
      }

      @media (min-width: 1090px) {
        width: 50%;
      }

      display: flex;
      vertical-align: middle;
      align-items: center;
      justify-content: center;

      $arrayModalidad: "card-modalidad-presencial"
          "glyphicon-modalidad-presencial" "presencial-apagado.svg"
          "presencial-encendido.svg" 40px 50px 55px 40px 60px,
        "card-modalidad-streaming" "glyphicon-modalidad-streaming"
          "streaming-apagado.svg" "streaming-encendido.svg" 15px 15px 15px 15px
          30px,
        "card-modalidad-blended" "glyphicon-modalidad-blended"
          "blended-apagado.svg" "blended-encendido.svg" 40px 55px 55px 30px 60px,
        "card-modalidad-autoestudio" "glyphicon-modalidad-autoestudio"
          "autoestudio-apagado.svg" "autoestudio-encendido.svg" 40px 40px 40px
          40px 55px;

      @each $class, $glyphicon, $url, $hover, $heightXL, $heightL, $heightM,
        $heightS, $heightXS in $arrayModalidad
      {
        .#{$class} {
          &:not(:hover) {
            .#{$glyphicon} {
              background-image: url(../../images/svg/modalidad/#{$url});
              background-position: center;
              background-repeat: no-repeat;
              background-size: 60%;
            }

            .card-modalidad-right {
              h3 {
                color: $blanco;
                transition: all 0.3s ease-in-out;
              }

              p {
                overflow: hidden;
                height: 0;
                opacity: 0;
                transition: all 0.3s ease-in-out;
              }
            }
          }

          &:hover {
            .#{$glyphicon} {
              background-image: url(../../images/svg/modalidad/#{$hover});
              background-position: center;
              background-repeat: no-repeat;
              background-size: 60%;
            }

            .card-modalidad-right {
              h3 {
                color: $morado;
                transition: all 0.3s ease-in-out;
              }

              p {
                @media (min-width: 1330px) {
                  height: $heightXL;
                }

                @media (min-width: 1290px) and (max-width: 1329.99px) {
                  height: $heightL;
                }

                @media (min-width: 1090px) and (max-width: 1289.99px) {
                  height: $heightM;
                }

                @media (min-width: 500px) and (max-width: 1089.99px) {
                  height: $heightS;
                }

                @media (max-width: 499.99px) {
                  height: $heightXS;
                }

                overflow: initial;
                opacity: 1;
                transition: all 0.3s ease-in-out;
              }
            }
          }
        }
      }

      .card-modalidad {
        min-height: 161px;
        width: 98%;
        display: flex;
        vertical-align: middle;
        align-items: center;
        margin-bottom: 18px;
        border: 1px solid $blanco;
        border-radius: 40px;

        .card-modalidad-left {
          width: 150px;
          height: 100px;
        }

        .card-modalidad-right {
          width: calc(100% - 150px);
          position: relative;

          h3 {
            font-family: $lato;

            @media (max-width: 1089.99px) {
              font-size: 26px;
            }

            @media (min-width: 1090px) {
              font-size: 30px;
            }
          }

          p {
            color: $negro;
            font-family: $lato;
            width: 90%;

            @media (max-width: 1089.99px) {
              font-size: 14px;
            }

            @media (min-width: 1090px) {
              font-size: 16px;
            }
          }
        }

        &:not(:hover) {
          background-color: $morado;
          transition: background-color 0.3s ease-in-out;
        }

        &:hover {
          background-color: $blanco;
          transition: background-color 0.3s ease-in-out;
        }
      }
    }
  }

  .enlace-arma-tu-curso {
    font-family: $lato;
    color: $blanco;
    font-weight: bold;
    text-align: center;
    width: 80%;

    @media (max-width: 799.99px) {
      font-size: 20px;
    }

    @media (min-width: 800px) {
      font-size: 30px;
    }
  }
}

.content-grid-courses {
  padding-top: 100px;

  .grid-courses {
    @media (max-width: 699.99px) {
      width: 90%;
    }

    @media (min-width: 700px) {
      width: 75%;
    }

    margin-left: auto;
    margin-right: auto;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;

    @media (max-width: 1279.99px) {
      flex-wrap: wrap;
    }

    .grid-card-courses {
      @media (min-width: 1280px) {
        width: calc(100% / 4);
        padding-top: 55px;
        padding-bottom: 55px;
      }

      @media (max-width: 1279.99px) {
        width: calc(100% / 2);
        padding-top: 25px;
      }

      .card-courses {
        min-height: 255px;
        width: 95%;
        border: 1px solid $blanco;
        border-radius: 40px;
        text-align: center;
        padding-left: 50px;
        padding-right: 50px;
        font-family: $lato;
        font-weight: bold;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        color: $blanco;

        @media (min-width: 700px) {
          font-size: 22px;
        }

        @media (max-width: 699.99px) {
          font-size: 18px;
        }
      }
    }
  }
}

.content-metricas {
  padding-top: 29px;
}

@keyframes fade-in-out-title {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes change-fadeout-fontsize-title {
  0% {
    font-size: 22px;
    opacity: 1;
  }

  100% {
    font-size: 22px;
    opacity: 1;
  }
}

@keyframes display-none {
  0% {
    display: none;
    overflow: hidden;
    height: 0;
  }

  100% {
    display: none;
    overflow: hidden;
    height: 0;
  }
}

@keyframes fade-out-cursos {
  0% {
    opacity: 1;
    display: flex;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-in-cursos {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-init-escuelas {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-init-escuelas-icons {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in-init-escuelas-cards {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
