@import "config";

.container-notfound {
  min-height: 80vh;
  background: linear-gradient(180deg, #5718b4, #2a065f);
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .animation-cohete {
    position: absolute;
    height: 120%;
    width: 70%;
    top: 0;
    left: -42%;
    border-radius: 50%;
    display: flex;
    vertical-align: middle;
    align-items: center;
    animation: rotate-cohete 10s infinite;

    @media (max-width: 799.99px) {
      display: none;
    }

    img {
      transform: rotate(-90deg);
    }
  }

  @keyframes rotate-cohete {
    0% {
      transform: rotate(-360deg);
    }

    100% {
      transform: rotate(0);
    }
  }

  .notfound-top {
    padding-top: 174px;

    .img-404 {
      @media (min-width: 1500px) {
        width: 591px;
      }

      @media (min-width: 1200px) and (max-width: 1499.99px) {
        width: 540px;
      }

      @media (min-width: 1000px) and (max-width: 1199.99px) {
        width: 450px;
      }

      @media (min-width: 800px) and (max-width: 999.99px) {
        width: 350px;
      }

      @media (max-width: 799.99px) {
        width: 250px;
      }
    }

    .img-exclamacion {
      margin-left: 34px;

      @media (min-width: 1500px) {
        width: 60px;
      }

      @media (min-width: 1200px) and (max-width: 1499.99px) {
        width: 55px;
      }

      @media (min-width: 1000px) and (max-width: 1199.99px) {
        width: 45px;
      }

      @media (min-width: 800px) and (max-width: 999.99px) {
        width: 35px;
      }

      @media (max-width: 799.99px) {
        width: 25px;
      }
    }
  }

  .notfound-bottom {
    @media (min-width: 800px) {
      width: 50%;
    }

    @media (max-width: 799.99px) {
      width: 80%;
    }

    margin-left: auto;
    margin-right: auto;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 98px;
    position: relative;

    p {
      font-family: $lato;
      color: $blanco;
      padding-bottom: 20px;

      @media (min-width: 800px) {
        font-size: 22px;
      }

      @media (max-width: 799.99px) {
        font-size: 18px;
      }
    }

    .button-return {
      margin-left: auto;
      margin-right: auto;
      background: linear-gradient(180deg, #ef3340, #db222f);
      text-align: center;
      font-family: $lato;
      color: $blanco;
      font-weight: bold;
      text-decoration: none;
      cursor: pointer;
      font-size: 22px;
      border-radius: 30px;
      padding-top: 16px;
      padding-bottom: 16px;
      box-shadow: 0px 0px 10px #0000004d;

      @media (min-width: 800px) {
        width: 60%;
      }

      @media (max-width: 799.99px) {
        width: 100%;
      }
    }

    img {
      position: absolute;
      transform: scaleX(-1);

      @media (min-width: 1300px) {
        width: 239px;
        height: 167px;
        right: -120px;
        bottom: -30%;
      }

      @media (min-width: 800px) and (max-width: 1299.99px) {
        width: 209px;
        height: 127px;
        right: -140px;
      }

      @media (max-width: 799.99px) {
        display: none;
      }
    }
  }
}
