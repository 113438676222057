@import "config";
@import "animate";

.categorias-destacadas {
  position: relative;
  z-index: 9999;

  @media (max-width: 1299px) {
    .item-box-arrow-curva-responsive {
      position: absolute;
      left: -60px;
      top: 3px;
    }
  }

  @media (min-width: 1300px) {
    .item-box-arrow-curva-responsive {
      display: none;
    }
  }

  .box-categorias-destacadas {
    width: 100%;
    margin-top: 30px;
    position: relative;
    margin-bottom: 100px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: 1299px) {
      .bcd-left {
        width: 90%;
        align-items: center;
      }

      .bcd-right {
        display: none;
      }
    }

    @media (min-width: 1300px) {
      .bcd-left {
        width: 25%;
        align-items: flex-end;
        padding-top: 50px;
      }

      .bcd-right {
        padding-top: 50px;
      }
    }

    .bcd-left {
      display: flex;
      flex-direction: column;
      animation: slide-up-scroll-animate 1s;
      z-index: 9999;

      @media (min-width: 1300px) {
        &:not(:hover) ~ .bcd-right > .card-categoria-initial {
          transform: translateX(0);
          transition: transform 0.5s ease-in-out;
        }

        &:hover ~ .bcd-right > .card-categoria-initial {
          transform: translateX(100%);
          transition: transform 0.5s ease-in-out;
        }
      }

      .item-categoria {
        width: 80%;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @media (min-width: 1300px) {
          &:hover {
            background-image: url(../../images/svg/arrow-right-3.svg);
            background-position: left;
            background-size: 20%;
            background-repeat: no-repeat;
          }
        }

        $arrayCategoriasDestacadas: "icon-liderazgo" 5px center,
          "icon-ventas" center center, "icon-bienestar" center center,
          "icon-food" center center, "icon-precontrato" center center,
          "icon-diversidad" center center;

        &:hover > .circle-categoria {
          @media (min-width: 1300px) {
            transform: translateX(100px);
            transition: transform 0.3s ease-in-out;
          }

          @each $glyphicon, $y, $x in $arrayCategoriasDestacadas {
            .#{$glyphicon} {
              background-color: $rojoImperial;
              background-image: url(../../images/svg/categorias/#{$glyphicon}-hover.svg);
              background-position-y: $y;
              background-position-x: $x;
              background-size: 50%;
              background-repeat: no-repeat;
              transition: background-image 0.3s ease-in-out;
            }
          }

          .circle-background-categoria {
            transition: background-color 0.1s ease-in-out;
          }
        }

        &:hover > .text-categoria {
          @media (min-width: 1300px) {
            transform: translateX(100px);
            transition: transform 0.3s ease-in-out;
          }

          background-color: $rojoImperial;
        }

        &:not(:hover) > .circle-categoria {
          @media (min-width: 1300px) {
            transform: translateX(0);
            transition: transform 0.3s ease-in-out;
          }

          @each $glyphicon, $y, $x in $arrayCategoriasDestacadas {
            .#{$glyphicon} {
              background-color: $miel;
              background-image: url(../../images/svg/categorias/#{$glyphicon}.svg);
              background-position-y: $y;
              background-position-x: $x;
              background-size: 50%;
              background-repeat: no-repeat;
              transition: background-image 0.3s ease-in-out;
            }
          }

          .circle-background-categoria {
            transition: background-color 0.1s ease-in-out;
          }
        }

        &:not(:hover) > .text-categoria {
          @media (min-width: 1300px) {
            transform: translateX(0);
            transition: transform 0.3s ease-in-out;
          }

          background-color: $morado;
        }

        @media (min-width: 1300px) {
          &:hover > .cards-categoria {
            transform: translateX(0);
            transition: transform 0.4s ease-in-out;
          }
        }

        &:not(:hover) > .cards-categoria {
          transform: translateX(-200%);
          transition: transform 0.4s ease-in-out;
        }

        .circle-categoria {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 10px;
          z-index: 9999;
          position: relative;

          .circle-background-categoria {
            position: absolute;
            width: 41px;
            height: 41px;
            border-radius: 50%;
            left: -1px;
            bottom: -3px;
          }

          .border-background-categoria {
            width: 42px;
            height: 42px;
            border: 1.5px solid $blanco;
            background: transparent;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        .text-categoria {
          width: calc(100% - 50px);
          text-align: center;
          color: $blanco;
          font-family: $lato;
          font-size: 1.1rem;
          background-color: $morado;
          border-radius: 30px;
          border: 1px solid $blanco;
          padding-top: 10px;
          padding-bottom: 10px;
          z-index: 9999;
        }

        .cards-categoria {
          width: 55%;
          height: 73%;
          position: absolute;
          bottom: 22%;
          left: 28%;
          display: flex;
          vertical-align: bottom;
          align-items: flex-end;
          justify-content: flex-end;
          z-index: 999;

          @media (max-width: 1299px) {
            display: none;
          }

          .card-item {
            width: 83%;
            background-color: $rojoImperial;
            min-height: 359px;
            border-radius: 50px;
            padding-left: 40px;
            display: flex;
            vertical-align: middle;
            align-items: center;
            position: absolute;

            .card-item-left {
              width: 50%;
              min-height: 359px;
              position: relative;
              display: flex;
              vertical-align: middle;
              align-items: flex-start;
              flex-direction: column;
              justify-content: center;

              .icon-gotas {
                position: absolute;
                top: -3%;
                left: -18%;
              }

              .title-card {
                color: $blanco;
                font-family: $lato;
                font-size: 30px;
                font-weight: bold;
                margin-bottom: 25px;
              }

              .paragraph-card {
                color: $blanco;
                font-weight: 300;
                font-family: $lato;
                font-size: 1rem;
              }

              .box-link-more {
                width: 85%;

                .link-more-card {
                  margin-top: 50px;
                  display: flex;
                  vertical-align: middle;
                  justify-content: center;
                  align-items: flex-end;
                  color: $blanco;
                  font-family: $roboto;
                  font-size: 1rem;
                  font-weight: bold;
                  text-decoration: none;
                  justify-content: right;

                  img {
                    margin-left: 5px;
                  }
                }
              }
            }

            .card-item-right {
              width: 50%;
              position: relative;
              min-height: 359px;

              $arrayCardsCategorias: "glyphicon-card-liderazgo" "liderazgo.png"
                  top center cover 400px,
                "glyphicon-card-ventas" "ventas.png" bottom left 115% 400px,
                "glyphicon-card-bienestar" "bienestar.png" bottom 20px 100%
                  450px,
                "glyphicon-card-food" "food.png" bottom 20px 105% 400px,
                "glyphicon-card-precontrato" "precontrato.png" center center
                  cover 400px,
                "glyphicon-card-diversidad" "diversidad.png" 10px 10px 130%
                  400px;

              @each $class, $url, $y, $x, $size,
                $height in $arrayCardsCategorias
              {
                .#{$class} {
                  background-image: url(../../images/png/cursos/#{$url});
                  background-position-y: $y;
                  background-position-x: $x;
                  background-size: $size;
                  background-repeat: no-repeat;
                  height: $height;
                }
              }

              .card-right-icons {
                position: absolute;
                width: 100%;
                max-width: 340px;
                height: 100%;

                .icon-estrella {
                  position: absolute;
                  width: 50px;
                  top: 20px;
                  left: 60px;
                }

                .icon-flecha-curva {
                  width: 100px;
                  position: absolute;
                  right: -10px;
                  top: 100px;
                  transform: rotate(60deg) scale(-1);
                }

                .icon-flecha {
                  width: 80px;
                  position: absolute;
                  bottom: 180px;
                  left: 40px;
                }

                .icon-signo-exclamacion {
                  position: absolute;
                  right: 50px;
                  top: 10px;
                  width: 30px;
                }

                .icon-linea-curva {
                  width: 50px;
                  position: absolute;
                  top: 80px;
                  left: 70px;
                }

                .icon-lineas {
                  position: absolute;
                  width: 50px;
                  transform: rotate(90deg);
                  right: 15px;
                  top: 10px;
                }

                .icon-linea-semicurva {
                  width: 90px;
                  position: absolute;
                  bottom: 130px;
                  left: 45px;
                }

                .icon-globo-heart {
                  width: 100px;
                  position: absolute;
                  top: 30px;
                  right: 130px;
                  transform: rotate(-20deg);
                }

                .icon-carpeta {
                  width: 90px;
                  position: absolute;
                  top: 60px;
                  left: 10px;
                  transform: rotate(-10deg);
                }

                .icon-arrow-top {
                  width: 100px;
                  position: absolute;
                  top: 50px;
                  left: 80px;
                }

                .icon-globo-persons {
                  width: 100px;
                  transform: rotate(10deg);
                  position: absolute;
                  top: 170px;
                  left: 100px;
                }
              }

              .card-right-gyphicon {
                position: absolute;
                width: 100%;
                max-width: 340px;
                bottom: 0;
                right: 0;
                border-bottom-right-radius: 50px;
              }
            }
          }
        }

        @media (max-width: 1299px) {
          &:last-child {
            margin-bottom: 100px;
          }
        }
      }
    }

    .bcd-right {
      width: 75%;
      position: relative;
      z-index: 99;
      animation: slide-up-scroll-animate 1.5s;
      overflow-x: hidden;

      .card-categoria-initial {
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;

        .cdi-left {
          width: 35%;
          height: 550px;

          .box-children {
            height: 350px;
            width: 80%;
            display: flex;
            vertical-align: bottom;
            align-items: flex-end;
            position: relative;

            .item-box-espiral {
              position: absolute;
              top: 0;
              width: 100%;
              display: flex;
              vertical-align: middle;
              align-items: center;
              justify-content: center;
              z-index: 999;
            }

            .item-box-verdelima {
              height: 260px;
              width: 100%;
              border-radius: 43px;
              background-color: $verdeLima;
              box-shadow: 0px 3px 6px #00000033;
              position: relative;
              display: flex;
              vertical-align: middle;
              justify-content: center;
              align-items: center;

              .hombre-home-categoria-destacada {
                width: 273px;
                height: 140%;
                bottom: 0;
                position: absolute;
                background-image: url(../../images/png/categorias/hombre_home_categorias_destacadas.png);
                background-position-x: center;
                background-size: cover;
                background-repeat: no-repeat;
                z-index: 9999;
              }
            }

            .item-box-arrow-curva {
              position: absolute;
              width: 100%;
              bottom: -15%;
              left: 0;

              img {
                margin-left: 90%;
              }
            }
          }
        }

        .cdi-right {
          width: 40%;
          height: 550px;
          display: flex;
          justify-content: flex-end;
          vertical-align: bottom;

          .box-children {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            vertical-align: bottom;
            width: 100%;
            position: relative;

            .item-box-globos {
              position: absolute;
              top: 10%;
              left: 0;
            }

            .item-box-exclamacion {
              position: absolute;
              right: 1%;
              bottom: calc(231px + 30px);
            }

            .item-box-miel {
              width: 80%;
              height: 231px;
              box-shadow: 0px 3px 6px #43000066;
              background-color: $miel;
              border-radius: 43px;
              position: relative;
              display: flex;
              vertical-align: middle;
              justify-content: center;
              align-items: center;

              .mujer-home-categoria-destacada {
                width: 291px;
                height: 348px;
                background-image: url(../../images/png/categorias/mujer-home-categoria-destacada.png);
                background-size: 100%;
                background-position: bottom;
                background-repeat: no-repeat;
                bottom: 0;
                position: absolute;
              }
            }
          }
        }
      }
    }

    .pipeline {
      position: absolute;
      height: calc(100% - 50px);
      width: 100%;
      top: 50px;
      animation: slide-up-scroll-animate 1s;

      .pipeline-left {
        position: absolute;
        width: 87%;
        bottom: 10%;
        border-bottom: 30px solid #ab9cc2;
        border-right: 30px solid #ab9cc2;

        @media (max-width: 1299px) {
          height: 35%;
          border-bottom-right-radius: 40px;
        }

        @media (min-width: 1300px) {
          height: 70%;
          border-bottom-right-radius: 100px;
        }
      }

      .pipeline-right {
        position: absolute;
        width: calc(13% + 30px);
        right: 0;
        border-top: 30px solid #ab9cc2;
        border-left: 30px solid #ab9cc2;

        @media (max-width: 1299px) {
          height: 20%;
          bottom: 42%;
          border-top-left-radius: 40px;
        }

        @media (min-width: 1300px) {
          height: 60%;
          border-top-left-radius: 100px;
        }
      }
    }
  }
}
