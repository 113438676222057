@import "config";

.header-campaign,
.banner-principal,
.contact-campaign,
.footer-campaign {
  min-width: 370px;
  overflow-x: hidden;
}

.header-campaign {
  background-color: #fff;

  @media (min-width: 1900px) {
    height: 109.31px;
  }

  @media (min-width: 1300px) and (max-width: 1900px) {
    height: 80px;
  }

  display: flex;
  vertical-align: middle;
  align-items: center;

  .content-header-campaign {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    @media (min-width: 1900px) {
      width: 338.25px;
      height: 65.31px;
    }

    @media (min-width: 1300px) and (max-width: 1900px) {
      width: 210px;
    }

    @media (min-width: 100px) and (max-width: 1300px) {
      width: 180px;
      height: 52px;
    }

    @media (max-width: 1000px) {
      width: 110px;
      height: 42px;
    }
  }
}

.banner-principal-section {
  display: flex;
  vertical-align: middle;
  background-color: #f18419;
  position: relative;
  overflow: hidden;

  @media (min-width: 800px) {
    align-items: flex-end;
  }

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    align-items: center;
  }

  .banner-principal-left-clear {
    @media (min-width: 800px) {
      width: 50%;
      height: 100%;
      position: absolute;
      background: #616a6b;
      border-radius: 0px 50px 50px 0px;
      box-shadow: 8px 4px 9px rgba(4, 54, 154, 0.14);
      z-index: 2;
    }

    @media (max-width: 800px) {
      display: none;
    }
  }

  .banner-principal-left {
    padding-top: 40px;
    padding-bottom: 40px;
    z-index: 3;
    position: relative;

    @media (min-width: 800px) {
      width: 50%;
    }

    @media (max-width: 800px) {
      background: #616a6b;
      border-radius: 50px 50px 0px 0px;
      box-shadow: 8px 4px 9px rgba(4, 54, 154, 0.14);
      width: 95%;
    }

    .owner-teamclass {
      margin-left: auto;
      margin-right: auto;

      @media (min-width: 930px) {
        width: 75%;
      }

      @media (max-width: 930px) {
        width: 250px;
      }

      label {
        font-family: "Lato";
        font-style: italic;
        font-weight: 300;
        display: flex;
        color: #fafafa;

        @media (min-width: 1670px) {
          font-size: 26px;
          line-height: 31px;
        }

        @media (min-width: 930px) and (max-width: 1670px) {
          font-size: 21px;
          line-height: 29px;
        }

        @media (max-width: 930px) {
          font-size: 15px;
          line-height: 20px;
        }
      }

      img {
        @media (min-width: 1670px) {
          width: 450px;
          height: 104px;
          margin-top: -22px;
        }

        @media (min-width: 930px) and (max-width: 1670px) {
          width: 350px;
          height: 80px;
          margin-top: -19px;
        }

        @media (max-width: 930px) {
          width: 250px;
          height: 80px;
          margin-top: -23px;
        }
      }
    }
  }

  .banner-principal-right {
    position: relative;
    z-index: 2;
    display: flex;
    vertical-align: middle;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;

    @media (min-width: 800px) {
      width: 50%;
    }

    @media (max-width: 800px) {
      width: 90%;
    }

    .box-banner-text {
      display: flex;
      vertical-align: middle;
      flex-direction: column;
      gap: 24px;
      width: 90%;

      @media (min-width: 1000px) and (max-width: 1100px) {
        margin-bottom: 50px;
      }

      @media (min-width: 800px) and (max-width: 1000px) {
        margin-bottom: 80px;
      }

      @media (min-width: 1355px) {
        align-items: flex-start;
      }

      @media (min-width: 800px) and (max-width: 1355px) {
        align-items: center;
      }

      @media (min-width: 500px) and (max-width: 800px) {
        align-items: flex-start;
      }

      @media (max-width: 500px) {
        align-items: center;
      }

      h2 {
        font-family: $lato;
        font-style: normal;
        font-weight: 700;
        color: #fafafa;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: 1580px) {
          font-size: 50px;
          line-height: 60px;
        }

        @media (min-width: 1320px) and (max-width: 1580px) {
          font-size: 40px;
          line-height: 50px;
        }

        @media (min-width: 1000px) and (max-width: 1320px) {
          font-size: 30px;
          line-height: 40px;
        }

        @media (min-width: 800px) and (max-width: 1000px) {
          font-size: 25px;
          line-height: 35px;
        }

        @media (min-width: 650px) and (max-width: 800px) {
          font-size: 40px;
          line-height: 50px;
        }

        @media (min-width: 650px) and (max-width: 650px) {
          font-size: 35px;
          line-height: 45px;
        }

        @media (min-width: 550px) {
          font-size: 30px;
          line-height: 40px;
        }

        @media (min-width: 800px) and (max-width: 1355px) {
          text-align: center;
          width: 70%;
        }
      }

      .box-text-principal {
        display: flex;
        vertical-align: middle;
        align-items: center;
        gap: 20px;
        width: 100%;
        justify-content: center;

        h3 {
          color: #fff;
          position: relative;
          text-transform: uppercase;
          font-family: $lato;

          @media (min-width: 1120px) {
            -webkit-text-stroke: 3px #fff;
            line-height: 140px;
          }

          @media (max-width: 1120px) {
            -webkit-text-stroke: 1px #fff;
            line-height: 110px;
          }

          @media (min-width: 1660px) {
            font-size: 200px;
          }

          @media (min-width: 1320px) and (max-width: 1660px) {
            font-size: 170px;
          }

          @media (min-width: 1120px) and (max-width: 1320px) {
            font-size: 130px;
          }

          @media (min-width: 920px) and (max-width: 1120px) {
            font-size: 100px;
          }

          @media (min-width: 800px) and (max-width: 920px) {
            font-size: 90px;
          }

          @media (min-width: 750px) and (max-width: 800px) {
            font-size: 150px;
          }

          @media (min-width: 650px) and (max-width: 750px) {
            font-size: 130px;
          }

          @media (min-width: 500px) and (max-width: 650px) {
            font-size: 110px;
          }

          @media (min-width: 500px) and (max-width: 530px) {
            font-size: 100px;
          }

          @media (min-width: 400px) and (max-width: 500px) {
            font-size: 80px;
          }

          @media (max-width: 400px) {
            font-size: 60px;
          }

          .hashtash-border-title {
            font-family: $lato;
            color: transparent;
            position: absolute;
            text-transform: uppercase;

            @media (min-width: 1120px) {
              top: 3px;
              left: 5px;
              line-height: 140px;
              -webkit-text-stroke: 2px rgb(27, 63, 87);
            }

            @media (max-width: 1120px) {
              top: 1.5px;
              left: 1.5px;
              line-height: 110px;
              -webkit-text-stroke: 1px rgb(27, 63, 87);
            }

            @media (min-width: 1660px) {
              font-size: 200px;
            }

            @media (min-width: 1320px) and (max-width: 1660px) {
              font-size: 170px;
            }

            @media (min-width: 1120px) and (max-width: 1320px) {
              font-size: 130px;
            }

            @media (min-width: 920px) and (max-width: 1120px) {
              font-size: 100px;
            }

            @media (min-width: 800px) and (max-width: 920px) {
              font-size: 90px;
            }

            @media (min-width: 750px) and (max-width: 800px) {
              font-size: 150px;
            }

            @media (min-width: 650px) and (max-width: 750px) {
              font-size: 130px;
            }

            @media (min-width: 530px) and (max-width: 650px) {
              font-size: 110px;
            }

            @media (min-width: 500px) and (max-width: 530px) {
              font-size: 100px;
            }

            @media (min-width: 400px) and (max-width: 500px) {
              font-size: 80px;
            }

            @media (max-width: 400px) {
              font-size: 60px;
            }
          }
        }

        .title-text {
          color: #fff;
          position: relative;
          text-transform: uppercase;
          font-family: $lato;
          padding-right: 10px;
          font-weight: 400;

          @media (min-width: 1660px) {
            font-size: 80px;
            line-height: 80px;
          }

          @media (min-width: 1480px) and (max-width: 1660px) {
            font-size: 75px;
            line-height: 75px;
          }

          @media (min-width: 1320px) and (max-width: 1480px) {
            font-size: 65px;
            line-height: 65px;
          }

          @media (min-width: 1120px) and (max-width: 1320px) {
            font-size: 55px;
            line-height: 55px;
          }

          @media (min-width: 920px) and (max-width: 1120px) {
            font-size: 45px;
            line-height: 45px;
          }

          @media (min-width: 800px) and (max-width: 920px) {
            font-size: 35px;
            line-height: 35px;
          }

          @media (min-width: 750px) and (max-width: 800px) {
            font-size: 65px;
            line-height: 65px;
          }

          @media (min-width: 650px) and (max-width: 750px) {
            font-size: 55px;
            line-height: 55px;
          }

          @media (min-width: 530px) and (max-width: 650px) {
            font-size: 45px;
            line-height: 45px;
          }

          @media (min-width: 500px) and (max-width: 530px) {
            font-size: 40px;
            line-height: 40px;
          }

          @media (min-width: 400px) and (max-width: 500px) {
            font-size: 35px;
            line-height: 35px;
          }

          b {
            font-weight: bold;
          }
        }

        .border-title-text {
          font-family: $lato;
          color: transparent;
          position: absolute;
          text-transform: uppercase;

          @media (min-width: 1330px) {
            -webkit-text-stroke: 2px rgb(27, 63, 87);
          }

          @media (min-width: 920px) and (max-width: 1330px) {
            -webkit-text-stroke: 1.5px rgb(27, 63, 87);
          }

          @media (max-width: 920px) {
            -webkit-text-stroke: 1px rgb(27, 63, 87);
          }

          @media (min-width: 1660px) {
            top: 3px;
            left: 2px;
          }

          @media (min-width: 1120px) and (max-width: 1660px) {
            top: 3px;
            left: 2px;
          }

          @media (max-width: 1120px) {
            top: 2px;
            left: 1px;
          }

          @media (min-width: 16600px) {
            font-size: 80px;
            line-height: 80px;
          }

          @media (min-width: 1480px) and (max-width: 1660px) {
            font-size: 75px;
            line-height: 75px;
          }

          @media (min-width: 1320px) and (max-width: 1480px) {
            font-size: 65px;
            line-height: 65px;
          }

          @media (min-width: 1120px) and (max-width: 1320px) {
            font-size: 55px;
            line-height: 55px;
          }

          @media (min-width: 920px) and (max-width: 1120px) {
            font-size: 45px;
            line-height: 45px;
          }

          @media (min-width: 800px) and (max-width: 920px) {
            font-size: 35px;
            line-height: 35px;
          }

          @media (min-width: 750px) and (max-width: 800px) {
            font-size: 65px;
            line-height: 65px;
          }

          @media (min-width: 650px) and (max-width: 750px) {
            font-size: 55px;
            line-height: 55px;
          }

          @media (min-width: 530px) and (max-width: 650px) {
            font-size: 45px;
            line-height: 45px;
          }

          @media (min-width: 500px) and (max-width: 530px) {
            font-size: 40px;
            line-height: 40px;
          }

          @media (min-width: 400px) and (max-width: 500px) {
            font-size: 35px;
            line-height: 35px;
          }

          b {
            font-weight: bold;
          }
        }

        .border-title-text {
          font-family: $lato;
          color: transparent;
          position: absolute;
          text-transform: uppercase;

          b {
            font-weight: bold;
          }
        }
      }

      p {
        font-family: $lato;
        font-style: normal;
        font-weight: 400;
        color: #fafafa;

        @media (min-width: 1800px) {
          width: 60%;
          font-size: 32px;
          line-height: 40px;
        }

        @media (min-width: 1710px) and (max-width: 1800px) {
          width: 50%;
          font-size: 30px;
          line-height: 38px;
        }

        @media (min-width: 1355px) and (max-width: 1710px) {
          width: 50%;
          font-size: 28px;
          line-height: 36px;
        }

        @media (min-width: 1000px) and (max-width: 1355px) {
          font-size: 25px;
          line-height: 31px;
        }

        @media (min-width: 800px) and (max-width: 1000px) {
          font-size: 20px;
          line-height: 26px;
        }

        @media (min-width: 650px) and (max-width: 800px) {
          font-size: 25px;
          line-height: 31px;
        }

        @media (min-width: 550px) and (max-width: 650px) {
          font-size: 26px;
          line-height: 35px;
        }

        @media (max-width: 550px) {
          font-size: 20px;
          line-height: 28px;
        }

        @media (min-width: 800px) and (max-width: 1355px) {
          width: 80%;
          text-align: center;
        }

        @media (min-width: 650px) and (max-width: 800px) {
          width: 48%;
        }

        @media (min-width: 550px) and (max-width: 650px) {
          width: 46%;
        }

        @media (min-width: 500px) and (max-width: 550px) {
          width: 37%;
        }

        @media (max-width: 500px) {
          width: 100%;
          text-align: center;
        }
      }
    }

    .banner-left-bottom {
      position: relative;
      display: flex;
      vertical-align: middle;

      @media (min-width: 1355px) {
        justify-content: right;
        align-items: flex-end;
      }

      @media (min-width: 800px) and (max-width: 1355px) {
        justify-content: center;
        align-items: center;
      }

      @media (max-width: 800px) {
        justify-content: right;
        align-items: flex-end;
      }

      @media (min-width: 1580px) {
        width: 90%;
        margin-top: -140px;
      }

      @media (max-width: 1580px) {
        width: 98%;
      }

      @media (min-width: 1355px) and (max-width: 1580px) {
        margin-top: -90px;
      }

      @media (max-width: 1355px) {
        margin-top: 0;
      }

      @media (min-width: 1300px) and (max-width: 1355px) {
        width: 500px;
      }

      @media (min-width: 800px) and (max-width: 1300px) {
        width: 400px;
      }

      @media (min-width: 500px) and (max-width: 800px) {
        width: 95%;
      }

      @media (min-width: 400px) and (max-width: 500px) {
        width: 300px;
      }

      @media (max-width: 400px) {
        width: 250px;
      }

      @media (min-width: 710px) and (max-width: 800px) {
        margin-top: -100px;
      }

      @media (min-width: 650px) and (max-width: 710px) {
        margin-top: -150px;
      }

      @media (min-width: 550px) and (max-width: 650px) {
        margin-top: -180px;
      }

      @media (min-width: 500px) and (max-width: 550px) {
        margin-top: -150px;
      }

      @media (max-width: 500px) {
        margin-top: 0;
      }

      .frame-persons {
        z-index: 4;

        @media (min-width: 1660px) {
          width: 550px;
          height: 550px;
        }

        @media (min-width: 1300px) and (max-width: 1660px) {
          width: 500px;
          height: 500px;
        }

        @media (min-width: 650px) and (max-width: 1300px) {
          width: 400px;
          height: 400px;
        }

        @media (min-width: 400px) and (max-width: 650px) {
          width: 300px;
          height: 300px;
        }

        @media (max-width: 400px) {
          width: 250px;
          height: 250px;
        }
      }

      .circle-persons {
        border-radius: 50%;
        background: #ffffff;
        position: absolute;
        z-index: 1;
        z-index: 3;

        @media (min-width: 1300px) {
          right: 90px;
        }

        @media (min-width: 1355px) and (max-width: 1300px) {
          right: 70px;
        }

        @media (min-width: 1300px) and (max-width: 1355px) {
          right: 95px;
        }

        @media (min-width: 1660px) {
          width: 300.96px;
          height: 300.76px;
          bottom: 230px;
        }

        @media (min-width: 1300px) and (max-width: 1660px) {
          width: 260.96px;
          height: 260.76px;
          bottom: 220px;
        }

        @media (min-width: 650px) and (max-width: 1300px) {
          width: 220.96px;
          height: 220.76px;
          bottom: 160px;
          right: 60px;
        }

        @media (min-width: 500px) and (max-width: 650px) {
          width: 170.96px;
          height: 170.76px;
          bottom: 115px;
          right: 45px;
        }

        @media (max-width: 500px) {
          width: 130.96px;
          height: 130.76px;
          bottom: 110px;
          right: 45px;
        }
      }

      .border-circle-persons {
        border-radius: 50%;
        border: 2px solid #18233d;
        position: absolute;
        z-index: 2;
        z-index: 3;

        @media (min-width: 1300px) {
          right: 75px;
        }

        @media (min-width: 1355px) and (max-width: 1300px) {
          right: 62px;
        }

        @media (min-width: 1300px) and (max-width: 1355px) {
          right: 85px;
        }

        @media (min-width: 1660px) {
          width: 300.96px;
          height: 300.76px;
          bottom: 230px;
        }

        @media (min-width: 1300px) and (max-width: 1660px) {
          width: 260.96px;
          height: 260.76px;
          bottom: 220px;
        }

        @media (min-width: 650px) and (max-width: 1300px) {
          width: 220.96px;
          height: 220.76px;
          bottom: 160px;
          right: 50px;
        }

        @media (min-width: 500px) and (max-width: 650px) {
          width: 170.96px;
          height: 170.76px;
          bottom: 115px;
          right: 35px;
        }

        @media (max-width: 500px) {
          width: 130.96px;
          height: 130.76px;
          bottom: 110px;
          right: 35px;
        }
      }
    }
  }

  .clear-background-waves {
    position: absolute;
    width: 100%;
    height: 80%;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    z-index: 1;
    background-size: cover;
    background-position: top;

    @media (min-width: 800px) {
      background-image: url(../../images/svg/campaign/ondas.svg);
    }

    @media (max-width: 800px) {
      background-image: url(../../images/svg/campaign/background-waver-responsive.svg);
    }
  }
}

.categories-section {
  background: #fff4d9;
  width: 100%;
  background-image: url(../../images/svg/campaign/background-lines.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 800px) {
    padding-top: 162px;
    padding-bottom: 162px;
  }

  @media (min-width: 600px) and (max-width: 800px) {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  @media (max-width: 600px) {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  .box-categories {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;

    h3 {
      font-family: $lato;
      font-style: normal;
      font-weight: 500;
      color: #262626;

      @media (max-width: 1150px) {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }

      @media (min-width: 1000px) {
        font-size: 64px;
        line-height: 77px;
      }

      @media (min-width: 800px) and (max-width: 1000px) {
        font-size: 44px;
        line-height: 57px;
      }

      @media (min-width: 600px) and (max-width: 800px) {
        font-size: 34px;
        line-height: 47px;
      }

      @media (min-width: 500px) and (max-width: 600px) {
        font-size: 28px;
        line-height: 40px;
      }

      @media (min-width: 400px) and (max-width: 500px) {
        font-size: 25px;
        line-height: 35px;
      }

      @media (max-width: 400px) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .title-categories {
      display: flex;
      vertical-align: middle;
      align-items: center;
      margin-top: -10px;

      @media (max-width: 1150px) {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }

      h2 {
        font-family: $lato;
        font-style: normal;
        font-weight: 900;
        color: #ff4900;

        @media (min-width: 1000px) {
          font-size: 96px;
          line-height: 115px;
        }

        @media (min-width: 800px) and (max-width: 1000px) {
          font-size: 76px;
          line-height: 95px;
        }

        @media (min-width: 600px) and (max-width: 800px) {
          font-size: 56px;
          line-height: 75px;
        }

        @media (min-width: 500px) and (max-width: 600px) {
          font-size: 46px;
          line-height: 55px;
        }

        @media (min-width: 400px) and (max-width: 500px) {
          font-size: 40px;
          line-height: 49px;
        }

        @media (max-width: 400px) {
          font-size: 35px;
          line-height: 49px;
        }
      }

      img {
        @media (min-width: 1000px) {
          width: 159.36px;
          height: 88.24px;
          margin-left: 40px;
        }

        @media (min-width: 400px) and (max-width: 1000px) {
          margin-left: 20px;
        }

        @media (min-width: 800px) and (max-width: 1000px) {
          width: 129.36px;
          height: 68.24px;
        }

        @media (min-width: 500px) and (max-width: 800px) {
          width: 109.36px;
          height: 48.24px;
        }

        @media (min-width: 400px) and (max-width: 500px) {
          width: 89.36px;
          height: 30.24px;
        }

        @media (max-width: 400px) {
          width: 90.36px;
          height: 30.24px;
          margin-left: 5px;
        }
      }
    }

    .cards-categories {
      display: flex;
      vertical-align: middle;
      align-items: center;
      justify-content: center;

      @media (min-width: 1600px) {
        gap: 200px;
      }

      @media (min-width: 1300px) and (max-width: 1600px) {
        gap: 150px;
      }

      @media (min-width: 600px) and (max-width: 1300px) {
        gap: 100px;
      }

      @media (min-width: 600px) {
        margin-top: 90px;
      }

      @media (max-width: 600px) {
        gap: 10px;
        row-gap: 30px;
        margin-top: 50px;
      }

      @media (max-width: 1150px) {
        flex-wrap: wrap;
      }

      .card-categorie {
        display: flex;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 32px;

        @media (min-width: 600px) and (max-width: 1150px) {
          width: calc(50% - 100px);
        }

        @media (max-width: 600px) {
          width: calc(50% - 10px);
        }

        .circle-card {
          @media (min-width: 1750px) {
            width: 228px;
            height: 228px;
          }

          @media (min-width: 1600px) and (max-width: 1750px) {
            width: 200px;
            height: 200px;
          }

          @media (min-width: 800px) and (max-width: 1600px) {
            width: 180px;
            height: 180px;
          }

          @media (min-width: 600px) and (max-width: 800px) {
            width: 143px;
            height: 143px;
          }

          @media (max-width: 600px) {
            width: 95px;
            height: 95px;
          }

          background-color: #ef7b1a;
          border-radius: 50%;
          display: flex;
          vertical-align: middle;
          align-items: center;
          justify-content: center;
          position: relative;

          img {
            @media (min-width: 1600px) {
              width: 106.81px;
              height: 143.6px;
            }

            @media (min-width: 800px) and (max-width: 1600px) {
              width: 86.81px;
              height: 123.6px;
            }

            @media (min-width: 600px) and (max-width: 800px) {
              width: 70px;
              height: 90px;
            }

            @media (max-width: 600px) {
              width: 46px;
              height: 62px;
            }
          }

          .circle-border-card {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 2px solid #010101;

            @media (min-width: 1600px) {
              top: 4px;
              left: 10px;
            }

            @media (min-width: 800px) and (max-width: 1600px) {
              top: 4px;
              left: 8px;
            }

            @media (min-width: 600px) and (max-width: 800px) {
              top: 2px;
              left: 6px;
            }

            @media (max-width: 600px) {
              top: 2px;
              left: 4px;
            }
          }
        }

        .paragraph-card {
          font-family: $lato;
          font-style: normal;
          font-weight: 500;
          color: #262626;

          @media (min-width: 1600px) {
            font-size: 40px;
            line-height: 48px;
          }

          @media (min-width: 800px) and (max-width: 1600px) {
            font-size: 30px;
            line-height: 43px;
          }

          @media (min-width: 600px) and (max-width: 800px) {
            font-size: 32px;
            line-height: 38px;
          }

          @media (min-width: 500px) and (max-width: 600px) {
            font-size: 22px;
            line-height: 26px;
          }

          @media (min-width: 400px) and (max-width: 500px) {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.whoiam-section {
  background-color: #fff;

  @media (min-width: 600px) {
    padding-top: 88px;
    padding-bottom: 88px;
  }

  @media (max-width: 600px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .box-whoiam {
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;

    @media (min-width: 1000px) {
      width: 80%;
    }

    @media (max-width: 1000px) {
      width: 90%;
    }

    .title-whoiam {
      display: flex;
      vertical-align: middle;
      align-items: center;

      @media (max-width: 1000px) {
        flex-direction: column-reverse;
      }

      h2 {
        font-family: $lato;
        font-style: normal;
        font-weight: 900;
        color: #ff4900;

        @media (min-width: 1800px) {
          font-size: 120px;
          line-height: 144px;
        }

        @media (min-width: 1550px) and (max-width: 1800px) {
          font-size: 100px;
          line-height: 124px;
        }

        @media (min-width: 1330px) and (max-width: 1550px) {
          font-size: 80px;
          line-height: 104px;
        }

        @media (min-width: 1000px) and (max-width: 1330px) {
          font-size: 60px;
          line-height: 84px;
        }

        @media (min-width: 800px) and (max-width: 1000px) {
          font-size: 75px;
          line-height: 100px;
        }

        @media (min-width: 600px) and (max-width: 800px) {
          font-size: 48px;
          line-height: 58px;
        }

        @media (max-width: 600px) {
          font-size: 32px;
          line-height: 38px;
        }
      }

      img {
        margin-left: 29px;

        @media (min-width: 1100px) {
          width: 307px;
          height: 186px;
        }

        @media (min-width: 1000px) and (max-width: 1100px) {
          width: 227px;
          height: 126px;
        }

        @media (min-width: 600px) and (max-width: 1000px) {
          width: 307px;
          height: 186px;
        }

        @media (max-width: 600px) {
          width: 147px;
          height: 89px;
        }
      }
    }

    .paragraph-whoiam {
      border: 2px solid #a3a3a3;
      border-radius: 32px;
      padding: 40px;
      margin-top: 40px;

      p {
        font-family: $lato;
        font-style: normal;
        font-weight: 500;
        color: #262626;

        @media (min-width: 1800px) {
          font-size: 48px;
          line-height: 58px;
        }

        @media (min-width: 1330px) and (max-width: 1800px) {
          font-size: 40px;
          line-height: 50px;
        }

        @media (min-width: 600px) and (max-width: 1330px) {
          font-size: 30px;
          line-height: 40px;
        }

        @media (max-width: 600px) {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
}

.experience-choise-section {
  background: #fff4d9;
  padding-top: 98px;
  padding-bottom: 148px;

  .box-experience-choise {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;

    .subtitle-experience-choise {
      font-family: $lato;
      font-style: normal;
      font-weight: 500;
      color: #262626;
      text-transform: uppercase;

      @media (min-width: 1000px) {
        font-size: 64px;
        line-height: 77px;
      }

      @media (min-width: 800px) and (max-width: 1000px) {
        font-size: 54px;
        line-height: 67px;
      }

      @media (min-width: 600px) and (max-width: 800px) {
        font-size: 44px;
        line-height: 47px;
      }

      @media (min-width: 500px) and (max-width: 600px) {
        font-size: 38px;
        line-height: 41px;
      }

      @media (min-width: 400px) and (max-width: 500px) {
        font-size: 25px;
        line-height: 25px;
      }

      @media (max-width: 400px) {
        font-size: 23px;
        line-height: 23px;
      }
    }

    .title-experience {
      display: flex;
      vertical-align: middle;
      align-items: center;
      gap: 10px;

      @media (min-width: 500px) {
        margin-top: -80.05px;
      }

      @media (max-width: 500px) {
        margin-top: -35px;
      }

      h2 {
        font-family: $lato;
        font-style: normal;
        font-weight: 900;
        color: #ff4900;
        text-transform: uppercase;

        @media (min-width: 1000px) {
          font-size: 94px;
          line-height: 113px;
        }

        @media (min-width: 800px) and (max-width: 1000px) {
          font-size: 74px;
          line-height: 93px;
        }

        @media (min-width: 600px) and (max-width: 800px) {
          font-size: 54px;
          line-height: 73px;
        }

        @media (min-width: 500px) and (max-width: 600px) {
          font-size: 45px;
          line-height: 63px;
        }

        @media (min-width: 400px) and (max-width: 500px) {
          font-size: 40px;
          line-height: 50px;
        }

        @media (max-width: 400px) {
          font-size: 30px;
          line-height: 53px;
        }

        .border-title {
          width: 100%;
          height: 20px;
          background-image: url(../../images/svg/campaign/curva.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }

      .like-box {
        @media (min-width: 600px) {
          width: 90.06px;
          height: 260.96px;
        }

        @media (min-width: 500px) and (max-width: 600px) {
          width: 70.06px;
          height: 260.96px;
        }

        @media (min-width: 400px) and (max-width: 500px) {
          width: 30.68px;
          height: 150.96px;
        }

        @media (max-width: 400px) {
          width: 40.68px;
          height: 150.96px;
        }

        img {
          @media (min-width: 600px) {
            width: 90.06px;
            height: 119.05px;
          }

          @media (min-width: 500px) and (max-width: 600px) {
            width: 70.06px;
            height: 99.05px;
          }

          @media (min-width: 400px) and (max-width: 500px) {
            width: 50.68px;
            height: 60px;
          }

          @media (max-width: 400px) {
            width: 40.68px;
            height: 40px;
          }
        }
      }
    }

    .cards-experience-choise {
      display: flex;
      vertical-align: middle;
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: 1100px) {
        flex-direction: column;
      }

      @media (min-width: 1600px) {
        row-gap: 54px;
      }

      @media (min-width: 1100px) and (max-width: 1600px) {
        row-gap: 30px;
      }

      @media (min-width: 600px) and (max-width: 1100px) {
        row-gap: 54px;
      }

      @media (min-width: 500px) and (max-width: 600px) {
        row-gap: 35px;
      }

      @media (min-width: 400px) and (max-width: 500px) {
        row-gap: 30px;
      }

      @media (max-width: 400px) {
        row-gap: 25px;
      }

      .card-experience {
        @media (min-width: 1100px) {
          width: 50%;
        }

        @media (min-width: 900px) and (max-width: 1100px) {
          width: 90%;
        }

        @media (max-width: 900px) {
          width: 100%;
        }

        display: flex;
        vertical-align: middle;
        align-items: center;
        justify-content: center;

        @media (min-width: 500px) {
          gap: 32px;
        }

        @media (max-width: 500px) {
          gap: 10px;
        }

        .card-experience-left {
          background: #ef7b1a;
          border-radius: 50%;
          display: flex;
          vertical-align: middle;
          align-items: center;
          justify-content: center;
          position: relative;

          @media (min-width: 1600px) {
            width: 101px;
            height: 101px;
          }

          @media (min-width: 1280px) and (max-width: 1600px) {
            width: 80px;
            height: 80px;
          }

          @media (min-width: 1100px) and (max-width: 1280px) {
            width: 65px;
            height: 65px;
          }

          @media (min-width: 800px) and (max-width: 1100px) {
            width: 101px;
            height: 101px;
          }

          @media (min-width: 600px) and (max-width: 800px) {
            width: 65px;
            height: 65px;
          }

          @media (max-width: 600px) {
            width: 52px;
            height: 52px;
          }

          img {
            @media (min-width: 1600px) {
              width: 53.21px;
              height: 61.67px;
            }

            @media (min-width: 1280px) and (max-width: 1600px) {
              width: 38.21px;
              height: 50.67px;
            }

            @media (min-width: 1100px) and (max-width: 1280px) {
              width: 28.21px;
              height: 50.67px;
            }

            @media (min-width: 800px) and (max-width: 1100px) {
              width: 53.21px;
              height: 61.67px;
            }

            @media (min-width: 600px) and (max-width: 800px) {
              width: 28.21px;
              height: 50.67px;
            }

            @media (max-width: 600px) {
              width: 22.21px;
              height: 40.67px;
            }
          }

          .circle-border-left {
            position: absolute;
            width: 100%;
            height: 100%;
            border: 1px solid #010101;
            border-radius: 50%;

            @media (min-width: 600px) {
              top: -3px;
              left: 3px;
            }

            @media (max-width: 600px) {
              top: -2px;
              left: 2px;
            }
          }
        }

        .card-experience-right {
          display: flex;
          vertical-align: middle;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          background: #262626;

          @media (min-width: 800px) {
            padding: 28px 40px;
          }

          @media (min-width: 1600px) {
            width: calc(100% - 101px - 28px * 2);
          }

          @media (min-width: 1280px) and (max-width: 1600px) {
            width: calc(100% - 80px - 28px * 2);
          }

          @media (min-width: 1100px) and (max-width: 1280px) {
            width: calc(100% - 65px - 28px * 2);
          }

          @media (min-width: 800px) and (max-width: 1100px) {
            width: calc(100% - 101px - 28px * 2);
          }

          @media (min-width: 600px) and (max-width: 800px) {
            padding: 15px 40px;
            width: calc(100% - 65px - 28px * 2);
          }

          @media (max-width: 600px) {
            width: calc(100% - 52px - 28px * 2);
            padding: 8px 20px;
          }

          label {
            font-family: $lato;
            font-style: normal;
            font-weight: 400;
            color: #fafafa;
            text-align: center;

            @media (min-width: 1800px) {
              font-size: 40px;
              line-height: 48px;
            }

            @media (min-width: 1600px) and (max-width: 1800px) {
              font-size: 34px;
              line-height: 42px;
            }

            @media (min-width: 1280px) and (max-width: 1600px) {
              font-size: 25px;
              line-height: 30px;
            }

            @media (min-width: 1100px) and (max-width: 1280px) {
              font-size: 20px;
              line-height: 20px;
            }

            @media (min-width: 800px) and (max-width: 1100px) {
              font-size: 34px;
              line-height: 42px;
            }

            @media (min-width: 600px) and (max-width: 800px) {
              font-size: 25px;
              line-height: 40px;
            }

            @media (min-width: 500px) and (max-width: 600px) {
              font-size: 20px;
              line-height: 35px;
            }

            @media (min-width: 400px) and (max-width: 500px) {
              font-size: 17px;
              line-height: 35px;
            }

            @media (max-width: 400px) {
              font-size: 15px;
              line-height: 25px;
            }
          }
        }

        .card-experience-left-transparent {
          @media (min-width: 1600px) {
            width: 101px;
          }

          @media (min-width: 1280px) and (max-width: 1600px) {
            width: 80px;
          }

          @media (min-width: 1150px) and (max-width: 1280px) {
            width: 65px;
          }

          @media (max-width: 1150px) {
            display: none;
          }
        }

        .card-experience-right-transparent {
          @media (min-width: 1600px) {
            width: calc(100% - 101px - 28px * 2);
          }

          @media (min-width: 1280px) and (max-width: 1600px) {
            width: calc(100% - 80px - 28px * 2);
          }

          @media (min-width: 1150px) and (max-width: 1280px) {
            width: calc(100% - 65px - 28px * 2);
          }

          @media (max-width: 1150px) {
            width: calc(100% - 28px);
          }

          padding: 12px 40px;
          border: 4px solid #d4d4d4;
          display: flex;
          vertical-align: middle;
          align-items: center;
          justify-content: center;
          border-radius: 50px;

          p {
            font-family: $lato;
            font-style: normal;
            font-weight: 400;
            color: #262626;

            @media (min-width: 1800px) {
              font-size: 32px;
              line-height: 38px;
            }

            @media (min-width: 1600px) and (max-width: 1800px) {
              font-size: 30px;
              line-height: 36px;
            }

            @media (min-width: 1340px) and (max-width: 1600px) {
              font-size: 25px;
              line-height: 31px;
            }

            @media (min-width: 800px) and (max-width: 1340px) {
              font-size: 20px;
              line-height: 25px;
            }

            @media (min-width: 600px) and (max-width: 800px) {
              font-size: 30px;
              line-height: 31px;
            }

            @media (max-width: 600px) {
              font-size: 20px;
              line-height: 25px;
            }

            a {
              margin-left: 8px;
              padding-left: 5px;
              font-style: oblique;
              font-weight: 300;
              color: #262626;
              border-bottom: 3px solid #ff4900;
              text-decoration: none;
            }
          }
        }
      }
    }

    .paragraph-experience-choise {
      background: rgb(133, 129, 118);
      padding: 33px 64px;
      position: relative;

      @media (min-width: 1100px) {
        border-radius: 70px;
        margin-top: 90px;
      }

      @media (max-width: 1100px) {
        border-radius: 30px;
        margin-top: 40px;
      }

      h3 {
        font-family: $lato;
        font-style: normal;
        font-weight: 900;
        display: flex;
        align-items: center;
        color: #fafafa;

        @media (min-width: 1100px) {
          font-size: 40px;
          line-height: 48px;
        }

        @media (min-width: 500px) and (max-width: 1100px) {
          font-size: 32px;
          line-height: 38px;
        }
      }

      p {
        font-family: $lato;
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;
        color: #fafafa;
        margin-top: 8px;

        @media (min-width: 1100px) {
          font-size: 38px;
          line-height: 46px;
        }

        @media (min-width: 500px) and (max-width: 1100px) {
          font-size: 16px;
          line-height: 19px;
        }
      }

      .border-paragraph-experience-choise {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 8px;
        border: 2px solid #010101;
        left: 0;

        @media (min-width: 1100px) {
          border-radius: 70px;
        }

        @media (max-width: 1100px) {
          border-radius: 30px;
        }
      }
    }
  }
}

.contact-bottom-section {
  min-height: 600px;
  background: #616a6b;
  box-shadow: 8px 4px 9px rgba(4, 54, 154, 0.14);
  border-radius: 50px 50px 0px 0px;
  margin-top: -50px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.contact-campaign {
  position: relative;
  z-index: 8;

  .title-secondary {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    vertical-align: middle;
    align-items: center;

    @media (min-width: 1120px) {
      width: 76%;
    }

    @media (max-width: 1120px) {
      width: 85%;
    }

    h2 {
      font-family: $lato;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
      color: #fafafa;
      position: relative;
      padding-bottom: 5px;
      display: flex;
      vertical-align: middle;
      align-items: center;

      @media (min-width: 930px) {
        font-size: 48px;
        line-height: 58px;
      }

      @media (min-width: 400px) and (max-width: 930px) {
        font-size: 40px;
        line-height: 50px;
      }

      @media (max-width: 400px) {
        font-size: 35px;
        line-height: 45px;
      }

      img {
        margin-left: 10px;

        @media (min-width: 930px) {
          width: 21px;
          height: 59px;
        }

        @media (max-width: 930px) {
          width: 18px;
          height: 50px;
        }
      }

      .line-title {
        position: absolute;
        width: 100%;
        height: 0.5px;
        background-color: #fafafa;
        bottom: 0;
      }
    }
  }

  .subtitle {
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 10px;

    @media (min-width: 1120px) {
      width: 76%;
    }

    @media (max-width: 1120px) {
      width: 85%;
    }

    h3 {
      font-family: $lato;
      font-style: normal;
      font-weight: 300;
      color: #fafafa;

      @media (min-width: 930px) {
        font-size: 32px;
        line-height: 38px;
      }

      @media (min-width: 600px) and (max-width: 930px) {
        font-size: 28px;
        line-height: 34px;
      }

      @media (max-width: 600px) {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }

  .form-contact-campaign {
    display: flex;
    vertical-align: middle;
    align-items: center;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    padding-top: 16px;

    @media (min-width: 1120px) {
      width: 85%;
    }

    @media (max-width: 1120px) {
      width: 95%;
    }

    .final-rows {
      padding-bottom: 10px;
    }

    .row-contat-campaign {
      display: flex;
      vertical-align: middle;
      align-items: center;
      width: 100%;
      justify-content: center;
      gap: 5px;
      z-index: 7;
      position: relative;
      flex-direction: column;

      @media (max-width: 1250px) {
      }

      .col-contact-campaign-2 {
        @media (min-width: 1250px) {
          width: 90%;
        }

        @media (max-width: 1250px) {
          width: 90%;
        }
      }

      .col-contact-campaign-1 {
        @media (min-width: 1250px) {
          width: calc(42% * 2 + 5%);
        }

        @media (max-width: 1250px) {
          width: 90%;
        }
      }

      .col-contact-campaign-2,
      .col-contact-campaign-1 {
        .layer-required {
          display: flex;
          font-family: $lato;
          font-style: italic;
          font-weight: 300;
          color: #ffb400;

          @media (min-width: 1420px) {
            font-size: 25px;
            line-height: 35px;
          }

          @media (max-width: 1420px) {
            font-size: 20px;
            line-height: 30px;
          }
        }

        .layer {
          display: flex;
          color: #fafafa;
          font-family: $lato;
          font-style: italic;
          font-weight: 300;

          @media (min-width: 1420px) {
            font-size: 25px;
            line-height: 35px;
          }

          @media (max-width: 1420px) {
            font-size: 20px;
            line-height: 30px;
          }

          i {
            font-family: $lato;
            font-style: italic;
            font-weight: 300;
            color: #ffb400;
            margin-left: 10px;

            @media (min-width: 1420px) {
              font-size: 25px;
              line-height: 35px;
            }

            @media (max-width: 1420px) {
              font-size: 20px;
              line-height: 30px;
            }
          }
        }

        .input-form-campaign {
          border-radius: 8px;
          padding: 8px 24px;
          font-family: $lato;
          font-style: italic;
          font-weight: 200;
          font-size: 20px;
          line-height: 28px;
          width: 100%;
          border: 2px solid #fafafa;
          background: #616a6b;
          color: #fff;

          &::placeholder {
            color: #aeaeae;
            font-weight: 300;
          }
        }

        .textarea-form-campaign {
          width: 100%;
          max-width: 100%;
          min-width: 100%;
          height: 86px;
          max-height: 86px;
          min-height: 86px;
          background: #616a6b;
          border-radius: 8px;
          resize: none;
          padding: 8px 24px;
          font-family: $lato;
          font-style: italic;
          font-weight: 200;
          font-size: 20px;
          line-height: 28px;
          border: 2px solid #fafafa;
          color: #fff;

          &::placeholder {
            color: #aeaeae;
            font-weight: 300;
          }
        }

        .button-action-newsletter {
          display: flex;
          vertical-align: middle;
          align-items: center;
          font-family: $lato;
          font-style: italic;
          font-weight: 300;
          color: #fafafa;
          margin-bottom: 10px;

          @media (min-width: 1670px) {
            font-size: 32px;
            line-height: 38px;
          }

          @media (min-width: 1325px) and (max-width: 1670px) {
            font-size: 25px;
            line-height: 30px;
          }

          @media (max-width: 1325px) {
            font-size: 20px;
            line-height: 25px;
          }

          button {
            background-color: transparent;
            cursor: pointer;
            display: flex;
            vertical-align: middle;
            align-items: center;
            color: #fafafa;

            @media (min-width: 1325px) {
              margin-right: 20px;
            }

            @media (max-width: 1325px) {
              margin-right: 10px;
            }

            svg {
              @media (min-width: 1325px) {
                width: 30px;
                height: 30px;
              }

              @media (max-width: 1325px) {
                width: 25px;
                height: 25px;
              }
            }
          }
        }

        .button-action-send {
          background: #ffae00;
          border-radius: 36px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px 40px;
          gap: 16px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;

          @media (min-width: 1250px) {
            float: right;
          }

          @media (max-width: 1250px) {
            margin-left: auto;
            margin-right: auto;
          }

          @media (min-width: 1000px) {
            font-size: 30px;
            line-height: 40px;
          }

          @media (min-width: 600px) and (max-width: 1000px) {
            font-size: 25px;
            line-height: 35px;
          }

          @media (max-width: 600px) {
            font-size: 20px;
            line-height: 30px;
          }

          img {
            @media (min-width: 1420px) {
              width: 53.51px;
              height: 40px;
            }

            @media (min-width: 600px) and (max-width: 1420px) {
              width: 56.11px;
              height: 39.41px;
            }

            @media (max-width: 600px) {
              width: 46.11px;
              height: 29.41px;
            }
          }
        }
      }

      .col-required {
        label {
          &::before {
            content: "(*)";
            color: #ffb400;
            margin-right: 5px;
            font-style: oblique;
            font-weight: 300;
          }
        }
      }
    }
  }
}

.footer-campaign {
  background-color: #fff;
  z-index: 9;
  position: relative;
  min-height: 300px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 1920px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  .footer-campaign-left {
    display: flex;
    vertical-align: middle;
    flex-direction: column;

    @media (min-width: 1000px) and (max-width: 1300px) {
      align-items: flex-end;
    }

    .box-teamclass {
      display: flex;
      vertical-align: middle;
      flex-direction: column;

      @media (min-width: 1300px) {
        align-items: flex-end;
      }

      @media (min-width: 1000px) and (max-width: 1300px) {
        align-items: flex-start;
        width: 80%;
      }

      @media (max-width: 1000px) {
        align-items: center;
      }

      .logo-footer-campaign {
        img {
          @media (min-width: 1400px) {
            width: 433.99px;
            height: 101px;
          }

          @media (min-width: 1000px) and (max-width: 1400px) {
            width: 184.77px;
            height: 43px;
          }

          @media (max-width: 1000px) {
            width: 290px;
            height: 90px;
          }
        }
      }

      h3 {
        font-family: $roboto;
        font-style: normal;
        font-weight: 500;
        color: #4c12a1;

        @media (min-width: 1000px) {
          margin-top: 24px;
        }

        @media (max-width: 1000px) {
          margin-top: 10px;
        }

        @media (min-width: 1400px) {
          font-size: 40px;
          line-height: 47px;
          text-align: right;
        }

        @media (max-width: 1400px) {
          font-size: 24px;
          line-height: 28px;
        }
      }

      .rrss-footer-campaign {
        display: flex;
        vertical-align: middle;
        align-items: center;
        padding-top: 24px;

        @media (min-width: 1400px) {
          gap: 34px;
        }

        @media (max-width: 1400px) {
          gap: 16px;
        }

        a {
          img {
            @media (min-width: 1400px) {
              width: 78px;
              height: 78px;
            }

            @media (max-width: 1400px) {
              width: 48px;
              height: 48px;
            }
          }
        }
      }
    }

    @media (max-width: 1000px) {
      align-items: center;
      width: 100%;
      border-bottom: 1px solid #4c12a1;
      padding-bottom: 30px;
    }

    @media (min-width: 1700px) {
      width: 35%;
    }

    @media (min-width: 1000px) and (max-width: 1700px) {
      width: 40%;
    }
  }

  .footer-campaign-right {
    display: flex;
    vertical-align: middle;
    align-items: flex-end;
    flex-direction: column;

    @media (min-width: 1700px) {
      width: 65%;
    }

    @media (min-width: 1000px) and (max-width: 1700px) {
      width: 60%;
    }

    @media (max-width: 1000px) {
      width: 100%;
    }

    .layer-info {
      display: flex;
      vertical-align: middle;
      flex-direction: column;
      gap: 10px;

      @media (min-width: 1000px) {
        width: 65%;
        align-items: flex-start;

        .responsive-campaign-matriz {
          display: flex;
        }
      }

      @media (max-width: 1000px) {
        width: 100%;
        align-items: center;
        padding-top: 20px;

        .responsive-campaign-matriz {
          display: none;
        }
      }

      .data-contact-footer {
        display: flex;
        vertical-align: middle;
        align-items: center;

        img {
          margin-right: 10px;

          @media (min-width: 1400px) {
            width: 68px;
          }

          @media (max-width: 1400px) {
            width: 40px;
          }
        }
      }

      .label-cm {
        font-family: $roboto;
        font-style: normal;
        font-weight: 400;
        color: #4c12a1;

        @media (min-width: 1000px) {
          display: none;
        }

        @media (max-width: 1000px) {
          display: flex;
        }

        @media (min-width: 1400px) {
          font-size: 30px;
          line-height: 35px;
        }

        @media (max-width: 1400px) {
          font-size: 21px;
          line-height: 35px;
        }
      }

      .label-ubicacion {
        font-family: $roboto;
        font-style: normal;
        font-weight: 400;
        color: #4c12a1;

        @media (min-width: 1900px) {
          font-size: 25px;
          line-height: 35px;
        }

        @media (min-width: 1300px) and (max-width: 1900px) {
          font-size: 20px;
          line-height: 35px;
        }

        @media (max-width: 1300px) {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
}
