.content {
    display: flex;
    vertical-align: middle;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 140px;
    padding-bottom: 200px;

    p {
        font-size: 1.1rem;
        color: #fff;
        font-weight: 200;
        font-family: Inter;
        margin-bottom: 20px;
        width: 70%;
        text-align: justify;
        line-height: 1.8rem;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
    }

    ul {
        width: 65%;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;

        li {
            font-size: 1.1rem;
            color: #fff;
            font-weight: 200;
            font-family: Inter;
            margin-bottom: 20px;
            text-align: justify;
            line-height: 1.8rem;
        }
    }
    
    h1 {
        font-size: 2.5rem;
        color: #fff;
        font-weight: 600;
        font-family: Inter;
        margin-top: 30px;
        margin-bottom: 50px;
        text-align: left;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }

    h2 {
        font-size: 1.6rem;
        color: #fff;
        font-weight: 600;
        font-family: Inter;
        margin-top: 30px;
        text-align: left;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }

    h3 {
        font-size: 1.3rem;
        color: #fff;
        font-weight: 600;
        font-family: Inter;
        margin-top: 30px;
        text-align: left;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
}