@import "config";

.front-page-nosotros {
  padding-top: 80px;
  width: 100%;
  position: relative;
  min-height: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1089.99px) {
    padding-top: 150px;
  }

  @media (min-width: 1090px) {
    padding-top: 80px;
  }

  .icon-meta {
    position: absolute;
    bottom: 10%;
    left: -250px;
    width: 574px;
    animation: fade-in-nosotros-init-meta 2s;

    @media (max-width: 1199.99px) {
      display: none;
    }
  }

  .icon-ampolleta {
    position: absolute;
    top: 0;
    width: 178px;
    right: 130px;
    animation: fade-in-nosotros-init-ampolleta 2s;

    @media (max-width: 1199.99px) {
      display: none;
    }
  }

  .front-nosotros-content {
    width: 50%;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 599.99px) {
      width: 90%;
    }

    @media (min-width: 600px) and (max-width: 699.99px) {
      width: 80%;
    }

    @media (min-width: 700px) and (max-width: 799.99px) {
      width: 70%;
    }

    @media (min-width: 800px) and (max-width: 899.99px) {
      width: 60%;
    }

    @media (min-width: 900px) {
      width: 50%;
    }

    @media (max-width: 1049.99px) {
      padding-top: 40px;
    }

    @media (min-width: 1050px) {
      padding-top: 80px;
    }

    .title {
      font-family: $lato;
      font-weight: bold;
      color: $blanco;
      text-align: center;
      position: relative;
      animation: fade-in-nosotros-init 1s;

      @media (max-width: 899.99px) {
        font-size: 40px;
        padding-left: 14px;
        padding-right: 14px;
      }

      @media (min-width: 900px) and (max-width: 1049.99px) {
        font-size: 50px;
        padding-left: 14px;
        padding-right: 14px;
      }

      @media (min-width: 1050px) {
        font-size: 64px;
        line-height: 77px;
      }

      .icon-gotas {
        position: absolute;
        transform: rotate(90deg);
        top: -40px;
        right: -10px;
        width: 79px;
        height: 58px;
        animation: fade-in-nosotros-init-gotas 1s;

        @media (max-width: 1199.99px) {
          display: none;
        }
      }
    }

    .subtitle {
      color: $miel;
      text-align: center;
      padding-top: 25px;
      padding-bottom: 15px;
      font-weight: bold;
      font-family: $lato;
      animation: fade-in-nosotros-init 1s;

      @media (max-width: 1049.99px) {
        font-size: 20px;
      }

      @media (min-width: 1050px) {
        font-size: 30px;
      }
    }

    .img-line {
      margin-left: auto;
      margin-right: auto;
      animation: fade-in-nosotros-init 1s;

      @media (max-width: 1049.99px) {
        width: 85%;
      }

      @media (min-width: 1050px) {
        width: 80%;
      }
    }

    .paragraph {
      font-family: $lato;
      color: $blanco;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      padding-top: 33px;
      animation: fade-in-nosotros-init 1s;

      @media (max-width: 1049.99px) {
        font-size: 18px;
        width: 95%;
      }

      @media (min-width: 1050px) {
        font-size: 22px;
        width: 80%;
      }
    }
  }
}

.nosotros-content-expirence {
  padding-top: 100px;
  padding-bottom: 50px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;

  .border-expirience {
    position: relative;
    width: 80%;
    min-height: 178px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fade-in-nosotros-init 1s;

    h3 {
      color: $blanco;
      font-family: $lato;
      text-align: center;
      font-weight: bold;
      text-align: center;
      animation: fade-in-nosotros-init 1s;
      padding: 30px;

      @media (max-width: 799.99px) {
        font-size: 20px;
      }

      @media (min-width: 800px) {
        font-size: 30px;
      }
    }

    .border-expirience-top {
      border: 1px solid $blanco;
      border-radius: 50px;
      width: 100%;
      height: 100%;
      position: absolute;
      transform: rotate(1deg);
      top: 0;
    }

    .border-expirience-bottom {
      border: 1px solid $blanco;
      border-radius: 50px;
      width: 100%;
      height: 100%;
      position: absolute;
      transform: rotate(-1deg);
      top: 0;
    }
  }
}

.nosotros-content-industrias {
  padding-top: 65px;

  .carrousel-industrias {
    padding-top: 37px;
    padding-bottom: 100px;
    animation: fade-in-nosotros-init 1s;

    .carrousel-bottom {
      margin-top: 19px;
    }

    .carrousel-bottom {
      .container-cards-industrias {
        @media (min-width: 1090px) {
          margin-left: calc(-23% * 3 - 14%);
        }

        @media (min-width: 920px) and (max-width: 1089.99px) {
          margin-left: calc(-30% * 4 - 19%);
        }

        @media (min-width: 700px) and (max-width: 919.99px) {
          margin-left: calc(-43% * 5 - 27%);
        }

        @media (max-width: 699.99px) {
          margin-left: calc(-70% * 6 - 37%);
        }
      }
    }

    .carrousel-top,
    .carrousel-bottom {
      position: relative;

      .overflow-carrousel {
        overflow-x: hidden;

        .container-cards-industrias {
          display: flex;

          @media (min-width: 1090px) {
            width: calc(23% * 8);
          }

          @media (min-width: 920px) and (max-width: 1089.99px) {
            width: calc(30% * 8);
          }

          @media (min-width: 700px) and (max-width: 919.99px) {
            width: calc(43% * 8);
          }

          @media (max-width: 699.99px) {
            width: calc(70% * 8);
          }

          .item-carrousel-line {
            @media (min-width: 1090px) {
              width: 23%;
            }

            @media (min-width: 920px) and (max-width: 1089.99px) {
              width: 30%;
            }

            @media (min-width: 700px) and (max-width: 919.99px) {
              width: 43%;
            }

            @media (max-width: 699.99px) {
              width: 70%;
            }
          }

          label {
            width: 95%;
            display: flex;
            vertical-align: middle;
            align-items: center;
            justify-content: center;
            background-color: #c1f8bf;
            padding-top: 26px;
            padding-bottom: 26px;
            color: #095d07;
            font-family: $lato;
            font-size: 22px;
            font-weight: bold;
            border-radius: 26px;

            &:not(:last-child) {
              margin-right: 15px;
            }
          }
        }

        .animate-carrousel-right {
          .item-carrousel-line {
            @media (min-width: 1090px) {
              animation: carrousel-industrias-left-min-1090px 30s infinite 1s;
            }

            @media (min-width: 920px) and (max-width: 1089.99px) {
              animation: carrousel-industrias-left-max-1089px 30s infinite 1s;
            }

            @media (min-width: 700px) and (max-width: 919.99px) {
              animation: carrousel-industrias-left-max-919px 60s infinite 1s;
            }

            @media (max-width: 699.99px) {
              animation: carrousel-industrias-left-max-699px 60s infinite 1s;
            }
          }
        }

        .animate-carrousel-left {
          .item-carrousel-line {
            @media (min-width: 1090px) {
              animation: carrousel-industrias-right-min-1090px 30s infinite 1s;
            }

            @media (min-width: 920px) and (max-width: 1089.99px) {
              animation: carrousel-industrias-right-max-1089px 30s infinite 1s;
            }

            @media (min-width: 700px) and (max-width: 919.99px) {
              animation: carrousel-industrias-right-max-919px 60s infinite 1s;
            }

            @media (max-width: 699.99px) {
              animation: carrousel-industrias-right-max-699px 60s infinite 1s;
            }
          }
        }

        // .animate-carrousel-left {
        //   label {
        //     animation: carrousel-industrias-right 30s infinite 1s;
        //   }
        // }
      }
    }
  }
}

@keyframes carrousel-industrias-left-min-1090px {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(calc(-100% * 3 + -62%));
  }

  100% {
    transform: translate(0);
  }
}

@keyframes carrousel-industrias-left-max-1089px {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(calc(-100% * 4 + -62%));
  }

  100% {
    transform: translate(0);
  }
}

@keyframes carrousel-industrias-left-max-919px {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(calc(-100% * 5 + -62%));
  }

  100% {
    transform: translate(0);
  }
}

@keyframes carrousel-industrias-left-max-699px {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(calc(-100% * 6 + -53%));
  }

  100% {
    transform: translate(0);
  }
}

@keyframes carrousel-industrias-right-min-1090px {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(calc(100% * 3 + 62%));
  }

  100% {
    transform: translate(0);
  }
}

@keyframes carrousel-industrias-right-max-1089px {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(calc(100% * 4 + 62%));
  }

  100% {
    transform: translate(0);
  }
}

@keyframes carrousel-industrias-right-max-919px {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(calc(100% * 5 + 62%));
  }

  100% {
    transform: translate(0);
  }
}

@keyframes carrousel-industrias-right-max-699px {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(calc(100% * 6 + 53%));
  }

  100% {
    transform: translate(0);
  }
}

// @keyframes carrousel-industrias-right {
//   0% {
//     transform: translateX(0);
//   }

//   50% {
//     transform: translateX(calc(100% * 4 - 15px * 7));
//   }

//   100% {
//     transform: translate(0);
//   }
// }

.movie-right {
  padding-bottom: 91px;

  @media (max-width: 1099.99px) {
    flex-direction: column-reverse;
  }
}

.movie-left {
  @media (max-width: 1099.99px) {
    flex-direction: column;
  }

  .text-movie {
    h3 {
      @media (min-width: 1100px) {
        margin-left: 20%;
      }
    }
  }
}

.movie-left,
.movie-right {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;

  @media (min-width: 1100px) {
    width: 80%;
  }

  @media (min-width: 1000px) and (max-width: 1099.99px) {
    width: 50%;
  }

  @media (min-width: 900px) and (max-width: 999.99px) {
    width: 60%;
  }

  @media (min-width: 800px) and (max-width: 899.99px) {
    width: 70%;
  }

  @media (min-width: 700px) and (max-width: 799.99px) {
    width: 80%;
  }

  @media (max-width: 699.99px) {
    width: 98%;
  }

  .text-movie {
    display: flex;
    vertical-align: middle;
    align-items: center;
    animation: fade-in-nosotros-init 1.5s;

    @media (min-width: 1300px) {
      width: 50%;
    }

    @media (max-width: 1299.99px) {
      width: 70%;
    }

    h3 {
      font-family: $lato;
      font-weight: bold;
      color: $blanco;

      @media (min-width: 1300px) {
        font-size: 48px;
        width: 60%;
        line-height: 57px;
      }

      @media (min-width: 1100px) and (max-width: 1299.99px) {
        width: 70%;
        font-size: 40px;
      }

      @media (max-width: 1099.99px) {
        padding-top: 50px;
        width: 100%;
        font-size: 30px;
      }
    }
  }

  .video-movie {
    animation: fade-in-nosotros-init 1s;
    position: relative;

    @media (max-width: 1099.99px) {
      display: flex;
      vertical-align: middle;
      align-items: center;
      justify-content: center;
    }

    @media (min-width: 1300px) {
      width: 50%;
    }

    @media (min-width: 1100px) and (max-width: 1299.99px) {
      width: 70%;
    }

    @media (max-width: 1099.99px) {
      width: 90%;
    }

    .card-video {
      min-height: 359px;
      width: 98%;
      border-radius: 53px;
      position: relative;
      overflow: hidden;

      video {
        width: 100%;
        height: 100%;
      }
    }

    .card-border-video {
      width: 100%;
      height: 100%;
      border-radius: 53px;
      border: 1px solid $blanco;
      position: absolute;
      transform: rotate(-5deg);
      top: 0;
    }
  }
}

.content-clientes {
  min-height: 100px;
}

.content-metricas {
  min-height: 500px;
}

.shadow-front-page {
  background: linear-gradient(180deg, #3d0096, #2a065f);
  display: block;
  z-index: 9999;
  min-height: 120vh;
}

@keyframes fade-in-nosotros-init-ampolleta {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-nosotros-init {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-nosotros-init-gotas {
  0% {
    transform: translateY(100px) rotate(90deg);
    opacity: 0;
  }

  100% {
    transform: translateY(0) rotate(90deg);
    opacity: 1;
  }
}

@keyframes fade-in-nosotros-init-meta {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
