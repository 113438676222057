@import "config";

.purple {
  background-color: $morado;
  box-shadow: $boxShadow;
  transition: background-color 0.2s ease-in-out;

  .logo {
    img {
      content: url(../../images/png/Logotipo_Negativo.png);
    }
  }

  .menu {
    .item-menu {
      color: $blanco;

      &:not(:hover) {
        color: $blanco;
        transition: color 0.2s ease-in-out;
      }
    }
  }
}

.light {
  background-color: $blanco;
  box-shadow: $boxShadow;
  transition: background-color 0.2s ease-in-out;

  .menu {
    .item-menu {
      color: $morado;

      &:not(:hover) {
        color: $morado;
        transition: color 0.2s ease-in-out;
      }
    }
  }
}

.transparent {
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;

  .logo {
    img {
      content: url(../../images/png/Logotipo_Negativo.png);
    }
  }
}

@media (max-width: 1090px) {
  .header {
    padding-bottom: 20px;
    align-items: flex-start;

    .logo {
      width: calc(100% - 90px);
      padding-top: 5px;

      img {
        width: 218px;
      }
    }

    .collapse-button-responsive {
      .svg-icon-menu {
        .svg-center {
          opacity: 0;
        }

        .svg-top {
          transform: rotate(45deg) translateY(-4px) translateX(3px);
        }

        .svg-bottom {
          transform: rotate(-45deg) translateY(4px) translateX(3px);
        }

        .svg-top,
        .svg-bottom,
        .svg-center {
          height: 4px;
          background-color: $blanco;
          width: 100%;
          border-radius: 3px;
          transform-origin: left;
          transition: all 0.5s;
        }
      }
    }

    .show-button-responsive {
      .svg-icon-menu {
        .svg-top,
        .svg-center,
        .svg-bottom {
          height: 4px;
          background-color: $blanco;
          width: 100%;
          border-radius: 3px;
          transition: all 0.5s;
        }
      }
    }

    .button-responsive-menu {
      width: 70px;
      height: 70px;
      position: absolute;
      display: flex;
      cursor: pointer;
      border-radius: 50%;
      left: 18px;
      top: 18px;
      background-color: transparent;
      z-index: 99999999;

      .background-menu {
        background-color: $rojoImperial;
        width: 100%;
        position: relative;
        height: 100%;
        border-radius: 50%;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-items: center;

        .svg-icon-menu {
          width: 36px;
          height: 36px;
          display: flex;
          flex-direction: column;
          vertical-align: middle;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
        }

        .border-menu {
          width: 100%;
          height: 100%;
          position: absolute;
          border: 2px solid $blanco;
          border-radius: 50%;
          right: -1px;
          bottom: -2px;
        }
      }
    }
  }
}

@media (min-width: 1090px) {
  .header {
    height: 60px;
    box-shadow: $boxShadow;
    align-items: center;

    .logo {
      width: 250px;

      img {
        width: 200px;
      }
    }

    .button-responsive-menu {
      display: none;
    }
  }
}

.header {
  width: 100%;
  position: fixed;
  z-index: 99999999;
  justify-content: center;
  display: flex;
  vertical-align: middle;

  .logo {
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1090px) {
    .menu {
      display: none;
    }

    .menu-responsive {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 99999;
      opacity: 1;
      animation: fade-in-menu-responsive 0.3s;

      .transparent-menu-responsive {
        position: fixed;
        left: 0;
        top: 0;
        background-color: rgba(#000, 0.4);
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .menu-responsive {
        background-color: #4c12a1;
        max-width: 400px;
        position: fixed;
        border-top-right-radius: 34px;
        border-bottom-right-radius: 34px;
        box-shadow: 0px 0px 8px #00000040;

        .logo {
          display: flex;
          padding-top: 90px;
          justify-content: center;
          align-items: center;
          vertical-align: auto;
          width: 80%;
          margin-right: auto;
          margin-left: auto;
          padding-bottom: 41px;
          border-bottom: 2px solid $blanco;
          margin-bottom: 47px;
        }

        .menu-xs {
          width: 80%;
          margin-right: auto;
          margin-left: auto;

          .item-menu {
            font-size: 24px;
            font-weight: bold;
            display: block;
            font-family: $lato;
            text-decoration: none;
            color: $blanco;

            &:not(:hover) {
              color: $blanco;
              transition: color 0.2s ease-in-out;
            }

            &:hover {
              color: $rojoImperial;
              transition: color 0.2s ease-in-out;
            }

            &:not(:last-child) {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }

  @keyframes fade-in-menu-responsive {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @media (min-width: 1090px) {
    .menu {
      display: flex;
    }

    .menu-responsive {
      display: none;
    }
  }

  .menu {
    width: calc(100% - 250px);
    vertical-align: middle;
    align-items: flex-end;
    justify-content: flex-end;

    .item-menu {
      font-family: $lato;
      text-decoration: none;
      font-size: 1rem;
      font-weight: 500;

      &:hover {
        color: $rojoImperial;
        transition: color 0.2s ease-in-out;
      }

      &:not(:last-child) {
        margin-right: 60px;
      }

      &:last-child {
        margin-right: 100px;
      }
    }
  }
}
