@import "config";
@import "animate";

.container-lo-mas-destacado {
  animation: slide-up-scroll-animate 1s;

  @media (min-width: 1090px) {
    width: 90%;
  }

  @media (min-width: 950px) and (max-width: 1089.99px) {
    width: 50%;
  }

  @media (min-width: 800px) and (max-width: 949.99px) {
    width: 55%;
  }

  @media (min-width: 700px) and (max-width: 799.99px) {
    width: 70%;
  }

  @media (max-width: 699.99px) {
    width: 90%;
  }

  background: linear-gradient(133deg, #7d46ce, #4c12a1);
  box-shadow: 3px 3px 10px #00000066;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50px;
  margin-top: 50px;
  margin-bottom: 80px;

  .lo-mas-destacado-top {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 27px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;

    .mark-actions-nav-responsive {
      padding-left: 20px;
      padding-right: 20px;

      @media (min-width: 1090px) {
        display: none;
      }

      label {
        font-family: $lato;
        font-size: 22px;
        color: $blanco;
        font-weight: bold;
      }
    }

    .button-move {
      background-color: transparent;
      display: flex;
      vertical-align: middle;
      align-items: center;

      svg {
        @media (max-width: 1089.99px) {
          width: 80px;
          height: 80px;
        }

        @media (min-width: 1090px) {
          width: 60px;
          height: 60px;
        }
      }

      &:not(:disabled) {
        cursor: pointer;
        color: $miel;
      }

      &:disabled {
        color: #b7a0d9;
      }
    }

    .buttons-actions-nav {
      width: calc(100% - 120px);
      margin-left: 10px;
      margin-right: 10px;
      background: linear-gradient(178deg, #3c0a86, #4c12a1, #3c0a86);
      box-shadow: 0px 0px 5px #ffb23d80;
      border-radius: 21px;
      overflow-x: hidden;

      @media (max-width: 1089.99px) {
        display: none;
      }

      .overflow-nav {
        flex-wrap: nowrap;
        display: inline-flex;
        width: max-content;

        .button-action-mav {
          scroll-snap-align: start;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-right: 50px;
          padding-left: 50px;
          font-family: $lato;
          font-size: 16px;
          font-weight: bold;
          border-radius: 21px;
          color: $blanco;

          &:not(:disabled) {
            background-color: transparent;
            cursor: pointer;
          }

          &:disabled {
            background: linear-gradient(
              180deg,
              #ffb400,
              #e5a200,
              #de9d00,
              #e6a200,
              #ffb400
            );
          }
        }
      }
    }
  }

  .lo-mas-destacado-bottom {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 22px;
    display: flex;
    align-items: flex-end;

    .lmd-bottom-left {
      width: 45%;
      display: flex;
      vertical-align: middle;
      align-items: center;
      justify-content: center;

      @media (max-width: 1089.99px) {
        display: none;
      }

      .background-img-lmd {
        height: 353px;
        width: 80%;
        background-image: url(../../images/png/hombre.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    .lmd-bottom-right {
      overflow-x: hidden;

      @media (max-width: 1089.99px) {
        width: 100%;
      }

      @media (min-width: 1090px) {
        width: 55%;
      }

      .overflow-carrousel {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        width: calc(100% * 5);

        .card-carrousel-lmd {
          width: 100%;
          transition: transform 0.8s ease-in-out;

          .card-title {
            display: flex;
            vertical-align: middle;
            align-items: center;
            width: 98%;
            margin-left: auto;
            margin-right: auto;

            @media (max-width: 1089.99px) {
              justify-content: center;
            }

            img {
              width: 45px;
            }

            h2 {
              @media (max-width: 1089.99px) {
                font-size: 22px;
              }

              @media (min-width: 1090px) {
                font-size: 35px;
              }

              color: $blanco;
              font-family: $lato;
              font-weight: bold;
              padding-left: 15px;
            }
          }

          .card-paragraph {
            color: $blanco;
            font-family: $lato;
            padding-top: 17px;
            padding-bottom: 17px;
            margin-left: auto;
            margin-right: auto;

            @media (max-width: 1089.99px) {
              width: 95%;
            }

            @media (min-width: 1090px) {
              width: 98%;
            }
          }

          .card-title-skills {
            color: $blanco;
            font-family: $lato;
            padding-bottom: 25px;
            display: flex;
            margin-left: auto;
            margin-right: auto;

            @media (max-width: 1089.99px) {
              width: 95%;
            }

            @media (min-width: 1090px) {
              width: 98%;
            }
          }

          .card-box-skills {
            display: flex;
            vertical-align: middle;
            align-items: center;
            padding-bottom: 60px;
            flex-wrap: wrap;
            margin-left: auto;
            margin-right: auto;

            @media (max-width: 1089.99px) {
              width: 95%;
            }

            @media (min-width: 1090px) {
              width: 98%;
            }

            .item-box-skills {
              background-color: $verdeLima;
              color: $blanco;
              font-family: $lato;
              font-size: 16px;
              padding: 8px 20px;
              border-radius: 17px;
              margin-bottom: 10px;

              &:not(:last-child) {
                margin-right: 10px;
              }
            }
          }

          .card-contact {
            display: flex;
            vertical-align: middle;
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 46px;

            @media (max-width: 1089.99px) {
              width: 95%;
              flex-direction: column;
            }

            @media (min-width: 1090px) {
              width: 98%;
              align-items: center;
            }

            label {
              font-family: $lato;
              font-size: 18px;
              color: $blanco;
              display: flex;
              margin-right: 8px;

              @media (max-width: 1089.99px) {
                padding-bottom: 8px;
              }
            }

            .enlace-contacto {
              color: $miel;
              font-size: 18px;
              font-family: $lato;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
