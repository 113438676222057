@import "config";

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999999;
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;

  .body-modal {
    width: 45%;
    min-height: 300px;
    background-color: $blanco;
    z-index: 99;
    position: relative;
    background-color: $morado;
    border-radius: 26px;

    .body-modal-content {
      position: relative;
      z-index: 999;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 30px;
      padding-bottom: 30px;
      display: flex;
      vertical-align: middle;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;

      p {
        padding-top: 30px;
        color: $blanco;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        font-size: 22px;
        text-align: center;
        font-family: $lato;
        line-height: 27px;
      }

      .button-close-modal {
        position: absolute;
        right: 0;
        top: 30px;
        background: transparent;
        color: $blanco;
        width: 21px;
        height: 21px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .body-modal-border {
      position: absolute;
      border: 1px solid $blanco;
      width: 100%;
      height: 100%;
      border-radius: 26px;
      transform: rotate(-2deg);
      top: 0;
      left: 0;
    }
  }

  .box-transparency-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.4);
    cursor: pointer;
  }
}
