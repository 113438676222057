@import "config";

.box-chat-flotante {
  @media (max-width: 1089.99px) {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999999999;
  }

  @media (min-width: 1090px) {
    width: 400px;
    min-height: 500px;
    bottom: 15%;
    right: 5%;
    z-index: 99999;
    box-shadow: $boxShadow;
    border: 1px solid $blanco;
    border-radius: 30px;
  }

  position: fixed;
  background-color: $blanco;
  opacity: 1;
  animation: fade-in 0.5s;
  padding: 30px;

  h2 {
    font-family: $lato;
    font-size: 2rem;
    color: $negro;
  }

  p {
    font-family: $lato;
    font-size: 1rem;
    padding-top: 10px;
    color: $negro;
    padding-bottom: 10px;
  }

  .contact {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.chat-flotante {
  position: fixed;
  bottom: 5%;
  right: 5%;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  cursor: pointer;
  background-color: $rojoImperial;
  box-shadow: $boxShadow;
  display: flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;

  .animate-chat-close {
    background-image: url(../../images/svg/boton-flotante.svg);
    background-size: 140%;
    background-position: center;
    background-repeat: no-repeat;
  }

  .animate-chat-open {
    background-image: url(../../images/svg/close-chat.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40%;
  }

  .animate-chat-close,
  .animate-chat-open {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  @media (max-width: 1089.99px) {
    z-index: 99999999999;
  }

  @media (min-width: 1090px) {
    z-index: 99999;
  }

  .border-chat {
    border: 2px solid $blanco;
    position: absolute;
    width: 100%;
    height: calc(100% + 1px);
    top: 0;
    border-radius: 50%;
    transform: translateY(2px) translateX(1px);
  }
}
