@import "config";

.carrusel-principal {
  z-index: 999;
  position: relative;

  @media (min-width: 1090px) {
    background: linear-gradient(180deg, #5718b4, #2a065f);
  }

  padding-bottom: 100px;

  .shadow-carrousel {
    @media (min-width: 1090px) {
      display: none;
    }

    background: linear-gradient(
      180deg,
      #5517b1,
      #5517b1f0,
      #5517b1e6,
      #5517b1bf,
      #5517b15e,
      #5517b100
    );
    width: 100%;
    height: 204px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .carrousel {
    width: 100%;
    // min-height: 80vh;
    display: flex;
    align-items: flex-end;

    @media (max-width: 1089.99px) {
      flex-direction: column-reverse;
    }

    .left {
      @media (max-width: 1089.99px) {
        width: 100%;
      }

      @media (min-width: 1090px) {
        width: 50%;
      }

      // min-height: 80vh;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;

      .overflow-carrousel {
        overflow-x: hidden;
        margin-left: auto;

        @media (min-width: 1090px) {
          width: 90%;
        }

        @media (min-width: 900px) and (max-width: 1089.99px) {
          width: 50%;
        }

        @media (max-width: 899.99px) {
          width: 80%;
        }

        @media (max-width: 1089.99px) {
          margin-right: auto;
        }

        @media (min-width: 1090px) {
          margin-right: 0;
        }

        .left-carrousel-top {
          width: calc(100% * 4);
          margin-left: -300%;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row-reverse;

          .item-title-carrousel {
            width: calc(100% / 4);
            transition: transform 2s ease-in-out;

            h1 {
              @media (min-width: 1450px) {
                font-size: 64px;
              }

              @media (min-width: 1230px) and (max-width: 1449.99px) {
                font-size: 54px;
              }

              @media (min-width: 1090px) and (max-width: 1229.99px) {
                font-size: 44px;
              }

              @media (max-width: 1089.99px) {
                font-size: 36px;
                text-align: center;
              }

              padding-top: 50px;
              padding-bottom: 52px;
              font-family: $lato;
              color: $blanco;
              font-weight: bold;
            }
          }
        }

        .left-carrousel-middle {
          padding-bottom: 30px;

          img {
            width: 100%;
          }
        }

        .left-carrousel-bottom {
          width: calc(100% * 4);
          margin-left: -300%;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row-reverse;

          .item-paragraph-carrousel {
            width: calc(100% / 4);
            transition: transform 2s ease-in-out;

            p {
              font-family: $lato;
              color: $blanco;

              @media (min-width: 1450px) {
                font-size: 22px;
              }

              @media (min-width: 1230px) and (max-width: 1449.99px) {
                font-size: 20px;
              }

              @media (min-width: 1090px) and (max-width: 1229.99px) {
                font-size: 18px;
              }

              @media (max-width: 1089.99px) {
                font-size: 16px;
                text-align: center;
              }
            }
          }
        }
      }

      .box-button-cursos {
        margin-left: auto;
        padding-top: 30px;

        @media (min-width: 1090px) {
          width: 85%;
          margin-right: 0;
        }

        @media (min-width: 900px) and (max-width: 1089.99px) {
          width: 50%;
        }

        @media (max-width: 899.99px) {
          width: 80%;
        }

        @media (max-width: 1089.99px) {
          margin-right: auto;
        }

        .enlace-cursos {
          @media (min-width: 1090px) {
            width: 80%;
            font-size: 22px;
          }

          @media (min-width: 900px) and (max-width: 1089.99px) {
            width: 100%;
            font-size: 22px;
          }

          @media (max-width: 899.99px) {
            width: 100%;
            font-size: 18px;
          }

          background: linear-gradient(180deg, #ef3340, #db222f);
          padding-top: 17px;
          padding-bottom: 17px;
          display: flex;
          vertical-align: middle;
          justify-content: center;
          font-family: $lato;
          color: $blanco;
          font-weight: bold;
          box-shadow: 0px 0px 10px #0000004d;
          border-radius: 30px;
          text-decoration: none;
        }
      }
    }

    .right {
      @media (max-width: 1089.99px) {
        width: 100%;
      }

      @media (min-width: 1090px) {
        width: 50%;
      }

      padding-top: 250px;
      width: 50%;
      // min-height: 80vh;
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      .icon-ampolleta {
        position: absolute;
        width: 130px;
        z-index: 9999;
        top: 0;

        @media (min-width: 700px) {
          left: 124px;
          width: 130px;
        }

        @media (max-width: 699.99px) {
          left: 30px;
          width: 120px;
        }

        animation: move-lamp-init 4s, move-lamp 6s infinite 4s;
      }

      .icon-flecha {
        position: absolute;

        @media (min-width: 700px) {
          right: 100px;
          top: 100px;
          width: 150px;
        }

        @media (max-width: 699.99px) {
          right: 50px;
          top: 150px;
          width: 100px;
        }

        animation: move-earthquake 6s infinite;
      }

      .icon-cohete {
        position: absolute;
        top: -400px;
        z-index: 9999;
        right: 100px;
        transform: rotate(-30deg);
        animation: move-rocket 2.5s;
      }

      @keyframes move-rocket {
        0% {
          transform: rotate(-30deg);
          top: 150px;
          left: 0;
        }

        15% {
          transform: rotate(-10deg);
          top: 150px;
          left: 0;
        }

        30% {
          transform: rotate(-30deg);
          top: 150px;
          left: 0;
        }

        40% {
          transform: rotate(-40deg);
        }

        70% {
          transform: rotate(-40deg);
          top: 100px;
          left: 20%;
        }

        80% {
          transform: rotate(-45deg);
          top: 0;
          left: 30%;
        }

        100% {
          transform: rotate(-40deg);
          top: -400px;
          left: 50%;
        }
      }

      @keyframes move-lamp-init {
        0% {
          transform: translateY(-200%);
        }

        100% {
          transform: translateY(-50%);
        }
      }

      @keyframes move-lamp {
        0% {
          transform: translateY(-50%);
        }

        50% {
          transform: translateY(0);
        }

        100% {
          transform: translateY(-50%);
        }
      }

      @keyframes move-earthquake {
        0% {
          transform: rotate(-20deg);
        }

        25% {
          transform: rotate(0);
        }

        50% {
          transform: rotate(20deg);
        }

        75% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(-20deg);
        }
      }

      @keyframes move-earthquake-lineas {
        0% {
          transform: rotate(240deg);
        }

        25% {
          transform: rotate(270deg);
        }

        50% {
          transform: rotate(290deg);
        }

        75% {
          transform: rotate(270deg);
        }

        100% {
          transform: rotate(240deg);
        }
      }

      .container-card-person {
        @media (min-width: 1090px) {
          width: 70%;
        }

        @media (min-width: 900px) and (max-width: 1089.99px) {
          width: 50%;
        }

        @media (max-width: 899.99px) {
          width: 80%;
        }

        position: relative;

        .icon-lineas {
          position: absolute;

          @media (min-width: 700px) {
            bottom: -50px;
            left: -60px;
            transform: rotate(270deg);
            width: 100px;
          }

          @media (max-width: 699.99px) {
            bottom: -30px;
            left: -40px;
            transform: rotate(270deg);
            width: 70px;
          }

          animation: move-earthquake-lineas 6s infinite;
        }

        .icon-carpeta {
          position: absolute;
          z-index: 999;
          @media (min-width: 700px) {
            width: 167px;
            bottom: 70px;
            right: -30px;
          }

          @media (max-width: 699.99px) {
            width: 100px;
            bottom: 60px;
            right: -30px;
          }

          animation: move-earthquake 6s infinite;
        }

        .box-person-carrousel {
          width: 100%;

          @media (min-width: 900px) {
            height: 359px;
          }

          @media (max-width: 899.99px) {
            height: 270px;
          }

          z-index: 999;
          border-radius: 60px;
          background: linear-gradient(147deg, #7d46ce, #4c12a1);
          box-shadow: 3px 3px 10px #00000066;
          position: relative;
          // animation: rotate-card-person 2s;

          .img-person-carrousel-1 {
            background-image: url(../../images/png/carrousel/carrousel-1-principal.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: bottom;
          }

          .img-person-carrousel-2 {
            background-image: url(../../images/png/carrousel/carrousel-2-principal.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: bottom;
          }

          .img-person-carrousel-3 {
            background-image: url(../../images/png/carrousel/carrousel-3-principal.png);
            background-repeat: no-repeat;
            background-position: bottom;
            background-size: contain;
          }

          .img-person-carrousel-4 {
            background-image: url(../../images/png/carrousel/carrousel-4-principal.png);
            background-size: contain;
            background-position: bottom;
            background-repeat: no-repeat;
          }

          .img-person-carrousel {
            width: 100%;
            position: absolute;
            bottom: 0;

            @media (min-width: 900px) {
              height: 502px;
            }

            @media (max-width: 699.99px) {
              height: 300px;
            }

            @media (min-width: 700px) and (max-width: 899.99px) {
              height: 400px;
            }
          }
        }

        @keyframes rotate-card-person {
          0% {
            transform: scaleX(1);
          }

          50% {
            transform: scaleX(0);
          }

          100% {
            transform: scaleX(1);
          }
        }
      }

      .pipeline {
        position: absolute;
        border-left: 30px solid $verdeLima;
        border-bottom: 30px solid $verdeLima;
        width: calc(50% + 30px);
        height: 70%;
        top: 0;
        right: 0;
        border-bottom-left-radius: 80px;
      }
    }
  }
}
