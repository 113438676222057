@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Livvic:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap);

$lato: "Lato", sans-serif;
$roboto: "Roboto", sans-serif;
$livvic: "Livvic", sans-serif;

$morado: #4c12a1;
$rojoImperial: #ef3340;
$miel: #ffb400;
$verdeLima: #53cc50;

$blanco: #fff;
$negro: #333;

$gris64: #646464;
$grisf0: #f0f0f0;
$grisd7: #d7d7d7;

$boxShadow: 0px 0px 10px rgba(0, 0, 0, 0.4);

* {
  border: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
